import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Select from 'react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Api } from '../../services/api';
import { getAuthToken, checkAuth } from '../../services/utils';

import { DateInput, CustomInput, CustomInputFile } from '../CustomInput/CustomInput';

import ResultForm from '../ResultForm/ResultForm';

import './NewNonStructured.scss';

const ACCESS_TYPE = {
  PRIVATE: 'private',
  PUBLIC: 'public',
  LIMITED: 'limited',
}

class NewNonStructured extends Component {

  state = {
    banks: null,
    document: null,
    limitedTo: [],
    accessType: ACCESS_TYPE.PRIVATE,
    expirationDate: null,
    file: null,
    documentName: '',
    documentHolder: '',
    isLoading: false,
    documentNameError: false,
    documentHolderError: false,
    fileError: false,
    limitedToError: false,
    errorMessage: '',
  }

  componentDidMount() {
    const authToken = getAuthToken();
    Api.get('v1/banks', authToken).then((response) => {
      const banks = response.data.map(bank => ({
        value: bank.publicKey,
        label: bank.bankName,
      }));
      this.setState({
        banks,
      });
    }).catch((error) => {
      checkAuth(error.response, this.props.history);
    });
  }

  render() {
    const {
      limitedTo, banks, document, accessType, expirationDate,
      documentName, documentHolder, isLoading, documentNameError,
      documentHolderError, fileError, limitedToError, errorMessage,
    } = this.state;
    return (
      <section>
        {document && <ResultForm document={document} />}
        {!document && (
          <div className="base">
            <div className="base__header">
              <div className="base__header-wrapper">
                <FontAwesomeIcon icon={faArrowLeft} onClick={this.goBack} />
                &nbsp;&nbsp;&nbsp;
                <h1 className="base__title">Issue Generic Document</h1>
              </div>
            </div>
            <form className="base__form" onSubmit={this.onSubmit}>
              {(documentNameError || documentHolderError || fileError || limitedToError) &&
                <div className="nonstructured__error error">
                  <label className="nonstructured__error-label error__label">{errorMessage}</label>
                </div>
              }

              <section className="edit-document">

                <h3 className="fullcell">Document details</h3>

                <CustomInput placeholder="Document Name" value={documentName} onChange={this.onDocumentNameChange} isError={documentNameError} />
                <CustomInput placeholder="Document Holder" value={documentHolder} onChange={this.onDocumentHolderChange} isError={documentHolderError} />

                <DateInput
                  selected={expirationDate}
                  onChange={this.handleExpirationDateChange}
                  placeholder="Expiration Date (optional)"
                  minDate={new Date(Date.now())}
                />

                <CustomInputFile placeholder="Attach File" accept="application/pdf" onChange={this.onFileChange} />

                <ul className="radio-list">
                  <li className={'radio-item ' + (accessType === ACCESS_TYPE.PRIVATE ? 'radio-item_active' : '')}
                    onClick={this.onChangeAccessType(ACCESS_TYPE.PRIVATE)}>
                    Private
                  </li>
                  <li className={'radio-item ' + (accessType === ACCESS_TYPE.PUBLIC ? 'radio-item_active' : '')}
                    onClick={this.onChangeAccessType(ACCESS_TYPE.PUBLIC)}>
                    Public
                  </li>
                  <li className={'radio-item ' + (accessType === ACCESS_TYPE.LIMITED ? 'radio-item_active' : '')}
                    onClick={this.onChangeAccessType(ACCESS_TYPE.LIMITED)}>
                    Limited
                  </li>
                </ul>

                <div>
                {accessType === ACCESS_TYPE.LIMITED && (
                  <p>Select bank...</p>)}
                  {accessType === ACCESS_TYPE.LIMITED && (
                    <Select className={(limitedToError ? 'select_error' : '') + ' input'}
                      value={limitedTo} onChange={this.handleMultiselect}
                      multi options={banks} clearable={false}
                    />
                  )}
                </div>

              </section>

              <p className="text-center">
                <button className="button" type="submit" disabled={isLoading}>
                  {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
                  {!isLoading && 'Submit'}
                </button>
              </p>
              
            </form>
          </div>
        )}
      </section>
    );
  }

  handleMultiselect = (selectedOption) => {
    this.setState({
      limitedTo: selectedOption,
      limitedToError: false,
    });
  }

  onFileChange = (file) => {
    this.setState({ file });
  }

  handleExpirationDateChange = (date) => {
    this.setState({
      expirationDate: date
    });
  }

  onDocumentNameChange = (e) => {
    this.setState({
      documentName: e.target.value,
      documentNameError: false,
    });
  }

  onDocumentHolderChange = (e) => {
    this.setState({
      documentHolder: e.target.value,
      documentHolderError: false,
    });
  }

  onChangeAccessType = (accessType) => () => {
    this.setState({
      accessType,
    })
  }

  onBlur = (fieldName, e) => {
    this.setState({
      [fieldName]: e.target.value.trim(),
    });
  }

  goBack = () => {
    this.props.history.goBack();
  }
  formValidate = () => {
    const {
      accessType, file, documentName, documentHolder, limitedTo
    } = this.state;

    let isValid = true;

    if (!documentName) {
      this.setState({
        errorMessage: "Form has invalid data",
        documentNameError: true,
      });

      isValid = false;
    }

    if (!file) {
      this.setState({
        errorMessage: "Form has invalid data",
        fileError: true,
      });

      isValid = false;
    }

    if (accessType === ACCESS_TYPE.LIMITED && limitedTo.length === 0) {
      this.setState({
        errorMessage: "Form has invalid data",
        limitedToError: true,
      });

      isValid = false;
    }

    if (!documentHolder) {
      this.setState({
        errorMessage: "Form has invalid data",
        documentHolderError: true,
      });

      isValid = false;
    }

    return isValid;
  }

  onSubmit = (e) => {
    e.preventDefault();
    const authToken = getAuthToken();
    const { accessType, expirationDate, documentName, documentHolder, limitedTo: rawLimitedTo } = this.state;

    if (this.formValidate()) {
      const issueTimestamp = Date.now();

      this.setState({
        isLoading: true,
      });

      let limitedTo = [];
      
      if (accessType === ACCESS_TYPE.LIMITED) {
        limitedTo = rawLimitedTo.map(item => item.value);
      }
      if (accessType === ACCESS_TYPE.PUBLIC) {
        limitedTo = ['public'];
      }

      this.getBase64(this.state.file).then((file) => {
        const fileBase64 = file.replace('data:application/pdf;base64,', "");
        Api.post('v1/document', {
          documentName: documentName,
          recipientName: documentHolder,
          issueTimestamp,
          expireTimestamp: expirationDate ? Date.parse(expirationDate) : null,
          limitedTo: limitedTo,
          data: fileBase64,
        }, authToken).then((response) => {
          this.setState({
            submited: true,
            document: response.data,
            isLoading: false,
          });
        }).catch((error) => {
          checkAuth(error.response, this.props.history);
          this.setState({
            errorMessage: error || 'Server Erorr',
            isLoading: false,
          })
        })
      })
    }
  }

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
}

export default withRouter(NewNonStructured);