import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

import { imageIcon } from './imageIcon';

function insertImage64(editor) {
  console.log('InsertImage64 was initialized', editor);

  function openModal() {
    const event = new CustomEvent('openModal', { detail: 'image' });
    window.dispatchEvent(event);
    return new Promise((resolve, reject) => {
      window.addEventListener('closeModal', evt => {
        resolve(evt.detail);
      }, false);
    })
  }

  editor.ui.componentFactory.add('insertImage64', locale => {
    const view = new ButtonView(locale);
    const model = editor.model;

    view.set({
      label: 'Insert image',
      icon: imageIcon,
      tooltip: true
    });

    // Callback executed once the image is clicked.
    view.on('execute', () => {
      openModal().then(modalResult => {
        model.change(writer => {
          const imageElement = writer.createElement('image', {
            src: modalResult.file64 || ''
          });
          // Insert the image in the current selection location.
          model.insertContent(imageElement, model.document.selection);
        });
      });
    });

    return view;
  });
}

export default insertImage64;