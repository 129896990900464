import Cookies from 'universal-cookie';

const cookies = new Cookies();
let _profile = null;

function expires(offsetSeconds = 24* 60 * 60 * 1000) {
  const now = new Date();
  return new Date(now.getTime() + offsetSeconds);
}

function deleteCookie(name) {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; Max-Age=-99999999; path="/"';
}

function getOptions(offsetSeconds) {
  return {
    expires: expires(offsetSeconds)
  };
}

/// PROFILE

export const setProfile = (profile) => {
  _profile = profile;
  profile && cookies.set('profile', profile, getOptions());
}

export const getProfile = () => {
  _profile = cookies.get('profile');
  return (_profile && _profile !== 'undefined') ? _profile : null;
}

export const refreshProfile = () => {
  setProfile(_profile);
}

export const updateTokens = (access_token, refresh_token) => {
  _profile.access_token = access_token;
  _profile.refresh_token = refresh_token;

  setProfile(_profile);
}

///

export const setAuthToken = (token) => {
  token && cookies.set('authToken', token, getOptions());
}

export const setRefreshToken = (token) => {
  
  token && cookies.set('refreshToken', token, getOptions());
}

export const getAuthToken = () => {
  return _profile && _profile.access_token;
}

export const getRefreshToken = () => {
  return _profile && _profile.refresh_token;
}

export const logout = (redirect) => {
  deleteCookie('profile');
  
  _profile = null;
  
  if (redirect)
    setTimeout(() => {
      window.location.href = window.location.protocol + '//' + window.location.host;
    });
}

export const checkAuth = (error, history) => {
  if (error && error.status === 401) {
    logout(true);
  }
}