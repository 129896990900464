import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';


import Protected from './components/Protected/Protected';
import Signin from './components/Signin/Signin';

class App extends Component {
  state = {
    hideCookies: localStorage.getItem('cookies')
  }

  render() {
    return (
      <section className="AppWrapper">
        <HashRouter>
          <Switch>
            <Route exact path="/" component={Signin} />
            <Route path="/explorer" component={Protected} />
          </Switch>
        </HashRouter>
        {!this.state.hideCookies &&
          <div className="land__cookies">
            <p>
              <span>
                We use cookies to give you the best possible experience on our site. By continuing to use the site you agree to our use of cookies.
              </span>
              <span className="button" onClick={this.onCookiesClick} >Got it</span>
            </p>
          </div>
        }
      </section>
    )
  }

  onCookiesClick = () => {
    localStorage.setItem('cookies', '1');
    this.setState({ hideCookies: true });
  }
};

export default App;