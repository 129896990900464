import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';

import { GlobalContext } from '../../services/context';

import { Api } from '../../services/api';
import { getAuthToken, setProfile } from '../../services/utils';

import { CustomInput } from '../CustomInput/CustomInput';

import './Signin.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function cn() { };

class Signin extends Component {

  state = {
    username: '',
    password: '',
    gacode: '',
    usernameError: false,
    passwordError: false,
    errorMessage: '',
    isCodeInput: false,
    codeError: false,
    loading: false,
    empty: true
  }

  block = false;
  href = window.location.href;

  static contextType = GlobalContext;

  componentDidMount() {
    let msg = [];
    const query = window.location.search;

    if (query) {
      msg = query.replace('?', '').split('=');
      window.history.pushState({}, document.title, '/');

      if (msg[0] === 'error') {
        alert(atob(msg[1]));
      }
    }
  }

  render() {
    if (getAuthToken()) {
      return <Redirect to="/explorer" />;
    }

    const { username, password, errorMessage, usernameError, passwordError, codeError, isCodeInput, gacode, loading, empty } = this.state;
    const { t } = this.props;
    return (
      <div className="signin">
        <form onSubmit={this.onSubmit} className="signin__form">
          <header className="signin__header">
            <div className="navbar-brand navbar-brand-large"></div>
            <h2 className="signin__title">Welcome to Cronica.</h2>
          </header>
          <div className="signin__body">
            {!errorMessage &&
              <p className="text-center">Sign In to use the service.</p>
            }
            {errorMessage &&
              <p className="signin__error error">
                <span>{errorMessage}</span>
              </p>
            }
            {!isCodeInput && (
              <div>
                <CustomInput value={username} placeholder="Username"
                  onChange={this.onChangeUsername} className={usernameError ? 'input_error' : ''} />
                <br />
                <CustomInput value={password} placeholder="Password" type="password"
                  onChange={this.onChangePassword} className={passwordError ? 'input_error' : ''} />
              </div>
            )}
            {!!isCodeInput && (
              <div>
                <input
                  className={cn('signin__body-input input', { 'input_error': codeError })}
                  onChange={this.onChangeCode}
                  type="text"
                  placeholder="One time authorization code"
                  value={gacode}
                />
              </div>
            )}
            <footer className="signin__footer">
              <a className="signin__footer-link" href={Api.BASE_URL + '/oauth2/authorization/google?redirect_uri=' + this.href}>Sign In by Google</a>
              <button type="submit" className="button" disabled={loading||empty}>
                  {loading && <FontAwesomeIcon icon={faSpinner} spin />}
                  {!loading && 'Sign In'}
                </button>
            </footer>

          </div>
        </form>
      </div>
    )
  }

  onChangeCode = (e) => {
    this.setState({
      gacode: e.target.value,
      odeError: false,
      errorMessage: '',
    });
  }

  onChangeUsername = (e) => {
    this.setState({
      username: e.target.value,
      usernameError: false,
      errorMessage: '',
    });
    if (e.target.value === '' || this.state.password === '') {

      this.setState({
        empty: true
      });
    } else {

      this.setState({

        empty: false
      });
    }
  }

  onChangePassword = (e) => {
    this.setState({
      password: e.target.value,
      passwordError: false,
      errorMessage: '',
    });
    if (this.state.username === '' || e.target.value === '') {

      this.setState({
        empty: true
      });
    } else {

      this.setState({
        empty: false
      });
    }
  }

  onSubmit = (e) => {
    this.setState({ codeError: false, loading: true });

    e.preventDefault();

    if (this.formValidate() && !this.block) {
      this.block = true;

      const { username, password, gacode } = this.state;

      this._signIn(username, password, gacode)
        .then(response => {
          setProfile(response);
          
          this.context.setUser(response);
          this.props.history.push('/explorer');
        }).catch(error => {
          if (!error.response) return error;

          const r = error.response.data;

          if (r.error_code) {
            if (r.error_code === '40121') {
              this.setState({ isCodeInput: true });
            }
            if (r.error_code === '40120') {
              this.setState({ codeError: true, errorMessage: r.error_description });
            }
            if (r.error_code === '40110') {
              this.setState({ passwordError: true, errorMessage: r.error_description });
            }
          }

          this.block = false;
        }).finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  formValidate = () => {
    const { username, password } = this.state;
    let isValid = true;

    if (!username && !password) {
      this.setState({
        usernameError: true,
        passwordError: true,
        errorMessage: 'Username and password are required fields',
      });

      isValid = false;
    } else if (!password) {
      this.setState({
        passwordError: true,
        errorMessage: 'Password is required field',
      });

      isValid = false;
    } else if (!username) {
      this.setState({
        usernameError: true,
        errorMessage: 'Username is required field',
      });

      isValid = false;
    }

    return isValid;
  }

  _getProfile(authToken, accessData) {
    return Api.get(`v1/profile`, authToken)
      .then(response => ({ ...response.data, ...accessData }));
  }

  _signIn(username, password, code) {
    const data = { username, password, code, grant_type: 'password' };
    return Api.postFormData('oauth/token', data)
      .then(response => {
        if (!response) throw new Error("Error!");
        else
          return this._getProfile(response.data.access_token, response.data);
      });
  }
}

export default withRouter(Signin);
