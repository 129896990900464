import React, {Component} from 'react';

import { Api } from '../../services/api';
import { getAuthToken, checkAuth } from '../../services/utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons'

import { CustomInput } from '../CustomInput/CustomInput';

import './EditUser.scss';

const ROLES = {
  USER: 'USER',
  EDITOR: 'EDITOR',
  ADMIN: 'ADMIN',
};

class EditUser extends Component {
  state = {
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    email: '',
    role: null,
    passwordError: '',
    confirmPasswordError: '',
    firstNameError: '',
    lastNameError: '',
    emailError: '',
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    currUser: null,
  }

  componentDidMount() {
      const authToken = getAuthToken();
      Api.get('v1/users', authToken).then((response) => {
        this.setData(response.data, this.props.match.params.id);
      }).catch((error) => {
        checkAuth(error.response, this.props.history);
      });
  }

  setData(users, id) {
    const user = users.find(user => user.id === id);

    user && this.setState({
      currUser: user,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      role: user.role,
    });
  }

  render() {
    const {
      password, firstName, lastName, email, isLoading, role, passwordError, firstNameError, currUser,
      lastNameError, emailError, errorMessage, successMessage, confirmPassword, confirmPasswordError
    } = this.state;

    return (
      <div>
        <div className="base">
          <div className="base__header">
            <div className="base__header-wrapper">
              <FontAwesomeIcon icon={faArrowLeft} onClick={this.onBackClick} />
              &nbsp;&nbsp;&nbsp;
              <h1 className="base__title">{`Edit ${currUser ? currUser.username : ''}`}</h1>
            </div>
          </div>
          <form className="base__form" onSubmit={this.onSubmit}>
            {(confirmPasswordError || passwordError || firstNameError || lastNameError || emailError) &&
              <div className="edituser__error error">
                <label className="edituser__error-label error__label">{errorMessage}</label>
              </div>
            }
            {successMessage && <div className="success-message">{successMessage}</div>}

            <section className="edit-user">
              <h3 className="fullcell">User Details</h3>
              <div className="fullcell">
                <CustomInput value={firstName} placeholder="UserName" disabled={true} />
              </div>
              <CustomInput value={firstName} onChange={this.onFirstNameChange} placeholder="First Name" isError={firstNameError} />
              <CustomInput value={lastName} onChange={this.onLastNameChange} placeholder="Last Name" isError={lastNameError} />
              <div className="fullcell">
                <CustomInput value={email} onChange={this.onEmailChange} placeholder="Email" isError={emailError} />
              </div>
              <CustomInput value={password} onChange={this.onPasswordChange} placeholder="Password" isError={passwordError} />
              <CustomInput value={confirmPassword} onChange={this.onConfirmPasswordChange} placeholder="Repeat Password" isError={passwordError} />
            
            
            <ul className="radio-list">
              <li className={'radio-item' + (role === ROLES.USER ? ' radio-item_active' : '')} onClick={this.onChangeRole(ROLES.USER)}>
                USER
              </li>
              <li className={'radio-item' + (role === ROLES.EDITOR ? ' radio-item_active' : '')} onClick={this.onChangeRole(ROLES.EDITOR)}>
                EDITOR
              </li>
                <li className={'radio-item' + (role === ROLES.ADMIN ? ' radio-item_active' : '')} onClick={this.onChangeRole(ROLES.ADMIN)}>
                ADMIN
              </li>
            </ul>

            </section>

            <div className="base__form-footer text-center">
              <button className="base__form-button button button_cancel" onClick={isLoading ? () => {} : this.onBackClick}>
                Cancel
              </button>
              &nbsp;
              <button
                className="base__form-button button"
                type="submit"
                disabled={isLoading}
              >
                {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
                {!isLoading && 'Save'}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  onSubmit = (e) => {
    e.preventDefault();
    const authToken = getAuthToken();
    const {
      password, firstName, lastName, email, role, currUser,
    } = this.state;

    if (this.formValidate()) {
      this.setState({
        isLoading: true,
      })

      Api.put(`v1/user/${currUser.id}`, {
        username: currUser.username,
        password: password !== '' ? password : null,
        firstName: firstName,
        lastName: lastName,
        email: email,
        role: role,
      }, authToken).then((response) => {
        this.setState({
          successMessage: response.data.username + ' edited'
        });

        this.props.history.push('/explorer/users');

        setTimeout(() => {
          this.setState({
            successMessage: '',
            isLoading: false,
          });
        }, 2000)
        
      }).catch((error) => {
        checkAuth(error.response, this.props.history);
        this.setState({
          isLoading: false,
        });
        alert('Error');
      });
    }
  }

  formValidate = () => {
    const {
      password, firstName, lastName, email, confirmPassword,
    } = this.state;
    
    const rePassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
    const reEmail = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    let isValid = true;

    if (password && !rePassword.test(password)) {
      this.setState({
        errorMessage: "Password should contain 8+ characters at least one big letter and one digit",
        passwordError: true,
      });

      isValid = false;
    }

    if (password !== confirmPassword) {
      this.setState({
        errorMessage: "Passwords do not match",
        passwordError: true,
        confirmPasswordError: true,
      })
      isValid = false;
    }

    if (!firstName) {
      this.setState({
        errorMessage: "Form has invalid data",
        firstNameError: true,
      });

      isValid = false;
    }

    if (!lastName) {
      this.setState({
        errorMessage: "Form has invalid data",
        lastNameError: true,
      });

      isValid = false;
    }

    if (!email) {
      this.setState({
        errorMessage: "Form has invalid data",
        emailError: true,
      });

      isValid = false;
    }

    if (!reEmail.test(email)){
      this.setState({
        errorMessage: "Email is not valid",
        emailError: true,
      })

      isValid = false;
    }

    return isValid;
  }

  onBackClick = () => {
    this.props.history.push('/explorer/users');
  }

  onChangeRole = (role) => () => {
    this.setState({
      role,
    })
  }

  onEmailChange = (e) => {
    this.setState({
      email: e.target.value,
      emailError: false,
    });
  }

  onPasswordChange = (e) => {
    this.setState({
      password: e.target.value,
      passwordError: false,
    });
  }

  onConfirmPasswordChange = (e) => {
    this.setState({
      confirmPassword: e.target.value,
      confirmPasswordError: false,
    });
  }

  onFirstNameChange = (e) => {
    this.setState({
      firstName: e.target.value,
      firstNameError: false,
    });
  }

  onLastNameChange = (e) => {
    this.setState({
      lastName: e.target.value,
      lastNameError: false,
    });
  }


  onBlur = (fieldName, e) => {
    this.setState({
      [fieldName]: e.target.value.trim(),
    });
  }
}

export default EditUser;
