const vaiableIcon = `<?xml version="1.0" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN"
 "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="900.000000pt" height="900.000000pt" viewBox="0 0 900.000000 900.000000"
 preserveAspectRatio="xMidYMid meet">
<metadata>
Created by potrace 1.15, written by Peter Selinger 2001-2017
</metadata>
<g transform="translate(0.000000,900.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M1825 7529 c-328 -43 -543 -196 -642 -460 -66 -175 -66 -173 -72
-1134 -6 -865 -6 -870 -28 -924 -56 -136 -103 -168 -269 -182 -58 -4 -122 -13
-142 -20 -131 -43 -210 -176 -200 -333 6 -86 32 -149 87 -209 57 -61 117 -85
243 -96 164 -14 231 -57 280 -178 l23 -58 6 -865 c6 -892 7 -912 50 -1069 68
-251 255 -434 511 -500 135 -35 255 -43 598 -39 317 3 326 4 372 26 64 31 131
108 149 169 8 26 14 82 13 123 -1 97 -30 166 -92 220 -64 57 -118 70 -282 70
-94 0 -153 5 -189 16 -61 18 -123 70 -152 127 -47 91 -49 125 -49 928 0 847
-2 873 -65 1008 -26 54 -54 93 -109 147 -115 115 -186 143 -511 200 l-40 7 30
8 c17 4 70 15 119 24 113 20 189 45 296 98 139 68 202 146 252 309 22 72 22
81 28 908 5 831 5 835 27 889 35 85 66 124 124 156 53 28 59 29 234 35 176 5
181 6 235 35 62 34 114 96 132 158 7 23 12 78 12 122 0 131 -52 219 -158 267
-50 23 -58 23 -401 24 -192 1 -381 -2 -420 -7z"/>
<path d="M6407 7529 c-89 -21 -152 -71 -187 -151 -60 -136 -19 -318 90 -393
62 -43 120 -55 265 -55 157 0 218 -15 276 -67 42 -38 68 -80 90 -148 10 -31
14 -225 19 -865 5 -741 8 -831 23 -885 71 -245 239 -380 537 -429 52 -9 110
-19 129 -22 18 -3 31 -7 29 -10 -3 -2 -60 -13 -127 -25 -229 -39 -325 -83
-437 -200 -70 -73 -94 -116 -126 -220 -22 -73 -22 -83 -28 -904 -5 -644 -9
-839 -19 -870 -22 -68 -48 -110 -90 -148 -58 -53 -118 -67 -282 -67 -175 0
-224 -14 -295 -85 -36 -36 -54 -64 -65 -100 -18 -63 -18 -166 0 -228 18 -61
85 -137 148 -168 47 -23 55 -24 363 -27 450 -5 593 14 770 101 165 81 297 246
349 438 43 157 44 177 50 1069 8 965 5 930 77 1010 52 57 107 80 219 91 124
11 162 22 214 59 106 78 157 240 116 377 -39 131 -136 206 -282 217 -207 17
-279 61 -324 201 -10 30 -14 247 -19 910 -7 961 -7 959 -73 1134 -102 270
-320 422 -661 460 -113 13 -695 13 -749 0z"/>
<path d="M3311 6235 c-132 -37 -267 -149 -329 -271 -62 -122 -76 -283 -36
-402 30 -88 100 -172 504 -608 223 -240 405 -442 405 -449 0 -7 -206 -235
-458 -506 -451 -487 -522 -569 -571 -660 -55 -100 -71 -257 -41 -388 42 -176
175 -312 360 -367 88 -26 242 -24 316 5 141 54 193 107 663 664 203 240 372
437 376 437 4 0 172 -195 373 -434 201 -238 401 -468 444 -511 142 -142 223
-180 388 -179 305 1 524 219 525 521 0 130 -37 236 -124 358 -20 28 -244 274
-499 549 -254 274 -462 504 -462 511 0 7 160 185 355 395 461 497 483 523 524
601 110 206 43 495 -146 637 -118 90 -219 119 -371 109 -96 -7 -176 -26 -239
-58 -73 -37 -167 -141 -461 -509 -164 -206 -302 -375 -307 -375 -4 0 -143 170
-309 378 -165 207 -324 399 -352 426 -60 57 -129 97 -203 118 -79 22 -260 26
-325 8z"/>
</g>
</svg>
`;

const imageIcon = `<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M6.91 10.54c.26-.23.64-.21.88.03l3.36 3.14 2.23-2.06a.64.64 0 0 1 .87 0l2.52 2.97V4.5H3.2v10.12l3.71-4.08zm10.27-7.51c.6 0 1.09.47 1.09 1.05v11.84c0 .59-.49 1.06-1.09 1.06H2.79c-.6 0-1.09-.47-1.09-1.06V4.08c0-.58.49-1.05 1.1-1.05h14.38zm-5.22 5.56a1.96 1.96 0 1 1 3.4-1.96 1.96 1.96 0 0 1-3.4 1.96z"/></svg>`;

const tableIcon = `<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 6v3h4V6H3zm0 4v3h4v-3H3zm0 4v3h4v-3H3zm5 3h4v-3H8v3zm5 0h4v-3h-4v3zm4-4v-3h-4v3h4zm0-4V6h-4v3h4zm1.5 8a1.5 1.5 0 0 1-1.5 1.5H3A1.5 1.5 0 0 1 1.5 17V4c.222-.863 1.068-1.5 2-1.5h13c.932 0 1.778.637 2 1.5v13zM12 13v-3H8v3h4zm0-4V6H8v3h4z"/></svg>`;


export { imageIcon, vaiableIcon, tableIcon};