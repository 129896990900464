import React, {Component} from 'react';

import { Api } from '../../services/api';
import { getAuthToken, checkAuth } from '../../services/utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { CustomInput } from '../CustomInput/CustomInput';

import './EditClient.scss';

const SCOPE = {
  READ: 'read',
  WRITE: 'write',
};

const TYPES = {
  PASSWORD: 'password',
  TOKEN: 'refresh_token',
};

class EditUser extends Component {
  state = {
    currClient: null,
    clientId: '',
    clientSecret: '',
    scope: SCOPE.READ,
    grantTypes: TYPES.PASSWORD,
    clientIdError: '',
    isLoading: false,
    errorMessage: '',
    successMessage: '',
  }

  static defaultProps = {
    clients: [],
  }

  componentDidMount() {
    if (this.props.clients.length > 0) {
      this.getClient(this.props.clients, this.props.match.params.id);
    } else {
      const authToken = getAuthToken();
      Api.get('v1/oauth2/clients', authToken).then((response) => {
        this.getClient(response.data, this.props.match.params.id);
      }).catch((error) => {
        checkAuth(error.response, this.props.history);
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.clients !== nextProps.clients) {
      // console.log(nextProps, this.props.clients, this.props.match.params);
      const client = nextProps.clients.filter(client => client.clientId === this.props.match.params.id);
      this.setState({
        currClient: client[0],
        clientId: client[0].clientId,
        clientSecret: client[0].clientSecret,
        grantTypes: client[0].grantTypes,
        scope: client[0].scope,
      })
    }
  }

  render() {
    const { clientId, clientSecret, scope, isLoading, currClient, successMessage, grantTypes } = this.state;

    return (
      <div>
        <div className="base">
          <div className="base__header">
            <div className="base__header-wrapper">
              <FontAwesomeIcon icon={faArrowLeft} onClick={this.onBackClick} />
              &nbsp;&nbsp;&nbsp;
              <h1 className="base__title">{`Edit ${currClient ? currClient.clientId : ''}`}</h1>
            </div>
          </div>
          <form className="base__form" onSubmit={this.onSubmit}>

            {successMessage && <div className="success-message">{successMessage}</div>}

            <section className="edit-user">
              <h3 className="fullcell">Client Details</h3>
              <div className="fullcell">
                <CustomInput value={clientId} placeholder="Client Id" disabled={true} />
              </div>
              <div className="fullcell">
                <CustomInput value={clientSecret} placeholder="Client Secret"  />
              </div>

              <ul className="radio-list">
                <li >
                  Scope:
                </li>
                <li className={'radio-item' + (scope === SCOPE.READ ? ' radio-item_active' : '')} onClick={this.onChangeScope(SCOPE.READ)}>
                    READ
                </li>
                <li className={'radio-item' + (scope === SCOPE.WRITE ? ' radio-item_active' : '')} onClick={this.onChangeScope(SCOPE.WRITE)}>
                    WRITE
                </li>
              </ul>

              <ul className="radio-list">
                <li>Grant Types:</li>
                <li className={'radio-item' + (grantTypes === TYPES.PASSWORD ? ' radio-item_active' : '')} onClick={this.onChangeGType(TYPES.PASSWORD)}>
                  PASSWORD
                </li>
                <li className={'radio-item' + (grantTypes === TYPES.TOKEN ? ' radio-item_active' : '')} onClick={this.onChangeGType(TYPES.TOKEN)}>
                  TOKEN
                </li>
              </ul>
            </section>

            <p className="text-center">
              <button className="button button_cancel" onClick={isLoading ? () => { } : this.onBackClick}>
                Cancel
              </button>
              &nbsp;
              <button className="button" type="submit" disabled={isLoading}>
                {isLoading && <i className="fa fa-spinner fa-spin" />}
                {!isLoading && 'Save'}
              </button>
            </p>
          </form>
        </div>
      </div>
    );
  }

  onSubmit = (e) => {
    e.preventDefault();
    const authToken = getAuthToken();
    const {
      clientId,
      scope,
      grantTypes,
    } = this.state;

    if (this.formValidate()) {
      this.setState({
        isLoading: true,
      })

      Api.put(`v1/oauth2/client`, {
        clientId,
        scope,
        grantTypes,
      }, authToken).then((response) => {
        this.setState({
          successMessage: response.data.clientId + ' edited'
        })

        this.props.history.push('/explorer/clients');

        setTimeout(() => {
          this.setState({
            successMessage: '',
            isLoading: false,
          });
        }, 2000)
        
      }).catch((error) => {
        checkAuth(error.response, this.props.history);
        this.setState({
          isLoading: false,
        })
      })  
    }
  }

  getClient = (arr, id) => {
    const client = arr.filter(client => client.clientId === id);
    this.setState({
      currClient: client[0],
      clientId: client[0].clientId,
      clientSecret: client[0].clientSecret,
      grantTypes: client[0].grantTypes,
      scope: client[0].scope,
    });
  }

  formValidate = () => {
    let isValid = true;

    return isValid;
  }

  onBackClick = () => {
    this.props.history.push('/explorer/clients');
  }

  onChangeScope = (scope) => () => {
    this.setState({
      scope,
    })
  }
  
  onChangeGType = (grantTypes) => () => {
    this.setState({
      grantTypes,
    })
  }

  onClientIdChange = (e) => {
    this.setState({
      clientId: e.target.value,
      clientIdError: false,
    });
  }

  onBlur = (fieldName, e) => {
    this.setState({
      [fieldName]: e.target.value.trim(),
    });
  }
}

export default EditUser;
