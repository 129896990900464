import React from 'react';
import Modal from 'react-modal';

const TYPE = {
  TABLE: 'table',
  VARIABLE: 'variable',
  IMAGE: 'image',
  FONT: 'font'
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '0',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '9999'
  }
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

class CkeModal extends React.Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false,
      type: TYPE.TABLE,
      data: {
        variableName: '',
        tableColumns: 2,
        tableName: '',
        file64: ''
      }
    };

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onFileSelected = this.onFileSelected.bind(this);

    window.addEventListener('openModal', this.openModal.bind(this), false);

    // setTimeout(() => {
    //   const event = new CustomEvent('openModal', { detail: TYPE.VARIABLE });
    //   window.dispatchEvent(event);
    // }, 1000)
  }

  openModal(evt) {
    this.setState({ modalIsOpen: true, type: evt.detail });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  onChange(evt, variableName) {
    const data = this.state.data || {};
    data[variableName] = evt.target.value;
    this.setState({ data });
  }

  onSubmit() {
    const event = new CustomEvent('closeModal', { detail: this.state.data });
    window.dispatchEvent(event);
    this.setState({ modalIsOpen: false });
  }

  onInputAttachClick = () => {
    document.getElementById('file64').click();
  }

  onFileSelected(evt) {
    const data = this.state.data || {};
    const file = evt.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      data.file64 = reader.result;
      data.fileName = file.name;
      this.setState({ data });
      this.onSubmit();
    }
    reader.readAsDataURL(file);
  }

  render() {
    const { type, modalIsOpen } = this.state;

    return (
      <div >

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="CkeModal"
        >
          <div className="modal-content">
            {type === TYPE.VARIABLE &&
              <div className="modal-body">
                <div className="form-group">
                  <label>Variable Name</label>
                  <input type="text" className="form-control" placeholder="VariableName" autoFocus onChange={evt => this.onChange(evt, 'variableName')} />
                  <small className="form-text text-muted">Please input variable system name </small>
                </div>
              </div>
            }

            {type === TYPE.IMAGE &&
              <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="img64-input-file" style={{ display: 'block' }}> Select image</label>
                  <input className="input input_file" style={{ margin: '20px 0 0' }} onClick={this.onInputAttachClick} type="text" readOnly placeholder="Attach File" />
                  <input className="input_hidden" id="file64" type="file" accept="image/*" onChange={this.onFileSelected}  />
                  <small className="form-text text-muted">Please select image file: PNG/JPG</small>
                </div>
              </div>
            }

            {type === TYPE.FONT &&
              <div className="modal-body">
                <div className="form-group">
                  <label style={{ display: 'block' }}>Select font file</label>
                  <input className="input input_file" style={{ margin: '20px 0 0' }} onClick={this.onInputAttachClick} type="text" readOnly placeholder="Attach File" />
                  <input className="input_hidden" id="file64" type="file" accept=".ttf,.otf" onChange={this.onFileSelected} />
                  <small className="form-text text-muted">Please select font file: OTF/TTF</small>
                </div>
              </div>
            }

            {type === TYPE.TABLE &&
              <div className="modal-body">
                <div className="form-group">
                  <label>Table Id</label>
                <input type="text" className="input" placeholder="TableId" autoFocus onChange={evt => this.onChange(evt, 'tableName')} />
                  <small id="emailHelp" className="form-text text-muted">Please input system table id</small>
                </div>
                <div className="form-group">
                  <label>Columns</label>
                <input type="number" min="1" step="1" className="input" placeholder="3" onChange={evt => this.onChange(evt, 'tableColumns')} />
                  <small id="emailHelp" className="form-text text-muted">Please input number of columns</small>
                </div>
              </div>
            }

            <div className="modal-footer">
              <button type="button" className="button" onClick={this.closeModal}>Close</button>
              <button type="button" className="button" onClick={this.onSubmit}>Submit</button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default CkeModal;