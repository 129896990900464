import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

import { toWidget } from '@ckeditor/ckeditor5-widget/src/utils';

import { vaiableIcon } from './imageIcon';


function insertVariable(editor) {
  console.log('test was initialized', editor);

  function openModal() {
    const event = new CustomEvent('openModal', { detail: 'variable' });
    window.dispatchEvent(event);
    return new Promise((resolve, reject) => {
      window.addEventListener('closeModal', evt => {
        resolve(evt.detail.variableName);
      }, false);
    })
  }

  editor.model.schema.register('mywidget', {
    allowWhere: '$text',
    isObject: true,
  });

  editor.conversion.for('dataDowncast')
    .elementToElement({
      model: 'mywidget',
      view: 'widget'
    });

  editor.conversion.for('editingDowncast')
    .elementToElement({
      model: 'mywidget',
      view: (modelItem, writer) => {
        const div = writer.createContainerElement('widget');
        return toWidget(div, writer, { label: 'widget label' });
      }
    });

  editor.conversion.for('upcast')
    .add(dispatcher => {
      dispatcher.on('element:widget', (evt, data, conversionApi) => {

        const writer = conversionApi.writer;
        const myModelElement = data.viewItem;
        const widget = writer.createElement('mywidget');
        const splitResult = conversionApi.splitToAllowedParent(widget, data.modelCursor);

        if (!splitResult) return;

        const el = myModelElement.getChild(0);
        const elData = el.data;

        el._data = '';

        writer.insertText(elData, widget);
        writer.insert(widget, data.modelCursor);

      }, { priority: 'highest' })
    });

  // ------------------

  editor.ui.componentFactory.add('insertVariable', locale => {
    const view = new ButtonView(locale);

    view.set({
      label: 'Insert variable',
      icon: vaiableIcon,
      tooltip: true
    });

    // Callback executed once the image is clicked.
    view.on('execute', () => {
      openModal().then(variableName => {
        editor.model.change(writer => {
          const myWidget = writer.createElement('mywidget');
          writer.insertText(variableName, myWidget);
          editor.model.insertContent(myWidget, editor.model.document.selection);
        });
      });
    });

    return view;
  });

}

export default insertVariable;