import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { format } from 'date-fns';

import Modal from 'react-modal';
import PDFViewer from 'mgr-pdf-viewer-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFileAlt, faCalendarAlt, faTimesCircle, faSpinner, faDownload, faEye, faTimes } from '@fortawesome/free-solid-svg-icons'

import { Api } from '../../services/api';
import { getAuthToken, checkAuth } from '../../services/utils';
import { GlobalContext, ROLES } from '../../services/context';
import { DateInput, CustomInput } from '../CustomInput/CustomInput'; 

import './InternalSearch.scss';

class InternalSearch extends Component {
  state = {
    documentId: '',
    documentHolder: '',
    documentName: '',
    issueTimestamp: null,
    errorMessage: '',
    errorResult: '',
    isLoading: false,
    documents: null,
    now: Date.now(),
    modalIsOpen: false,
    activeDocument: null
  }
  static contextType = GlobalContext;
 
  render() {
    const { role } = this.context.user;
    const {
      documentId, documentHolder, documentName, issueTimestamp, errorMessage, errorResult, isLoading, documents, now
    } = this.state;

    return (
      <div className="base">
        <div className="base__header">
          <h1 className="base__title">Issued Documents</h1>
          <div className="base__btns">
            <button className="button" onClick={this.onNewDocumentClick}>New Document</button>
            {((role === ROLES.ADMIN)||(role === ROLES.EDITOR))&&
            <button className="button" onClick={this.onNewTemplateClick}>New Template</button>
            }
          </div>
        </div>
        <form className="base__form base__form-internal-saech" onSubmit={this.onSubmit}>
          {!errorMessage && <p>Search for existing documents</p>}
          {errorMessage && <p className="red">{errorMessage}</p>}
          <section className="multiple-inputs">
            <CustomInput placeholder="Document ID" className="input-first" isError={!!errorMessage} 
              onChange={this.onDocumentIdChange} value={documentId} />
            <CustomInput placeholder="Document Holder" isError={!!errorMessage}
              onChange={this.onDocumentHolderChange} value={documentHolder} disabled={!!documentId} />
            <CustomInput placeholder="Document Name" isError={!!errorMessage}
              onChange={this.onDocumentNameChange} value={documentName} disabled={!!documentId} />
            <DateInput placeholder="Issue Date" className="input-last"
              onChange={this.onIssueDateChange} selected={issueTimestamp}
              disabled={!!documentId} isError={!!errorMessage} />
            <button className="button" type="submit" disabled={isLoading}>
              {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
              {!isLoading && <FontAwesomeIcon icon={faSearch} />}
            </button>
          </section>
        </form>
        {errorResult &&
          <div className="internal-search__result">
            <div className="internal-search__result-title">{errorResult}</div>
            <div className="internal-search__result-error-hint">
              Please verify the information you entered above or try using less specific search criteria.
            </div>
          </div>
        }
        {documents && documents.length > 0 &&
          <div className="internal-search__result">
          <div className="internal-search__result-title">
            Search Results:
          </div>
            {documents.map((document, i) => (
              <div key={i} className="internal-search__result-document">
                <div className="document-icon">
                  <FontAwesomeIcon icon={faFileAlt} />
                </div>
                <div className="document-name">
                  <h3>{document.documentName}</h3>
                  <span>by <i>{document.recipientName}</i></span>
                </div>
                <div className="document-dates">
                  <span className="document-dates-icon">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </span>
                  <span className="document-dates-date">
                    Issued: <i>{format(new Date(document.issueTimestamp), 'dd/MM/yyyy')}</i>
                  </span>
                  <span className="document-dates-icon">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </span>
                  <span className="document-dates-date">
                    Expires: <i>{document.expireTimestamp ? format(new Date(document.expireTimestamp), 'dd/MM/yyyy') : 'N/A'}</i>
                  </span>
                </div>
                <div className="document-revoke">
                  {document.documentLink && ((!!document.isRevoked) ? (
                    <span className="">Revoked</span>
                  ) : (
                      <section>
                        {(!!document.expireTimestamp && (document.expireTimestamp <= now)) ? (
                          <span>Expired</span>
                        ) : (
                            <span disabled={document.isLoading} className="btn" onClick={() => this.onRevoke(document, documents)}>
                              {document.isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
                              {!document.isLoading && (<span><FontAwesomeIcon icon={faTimesCircle} /> Revoke</span>)}
                            </span>
                          )}
                      </section>
                    ))}
                </div>
                <div className="document-actions">
                  {document.documentLink && (
                    <span onClick={event => this.togglePreviewModal(document)}>
                      <FontAwesomeIcon icon={faEye} /> Preview
                    </span>
                  )}
                  {document.documentLink && (
                    <a href={document.documentLink} target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faDownload} /> Download
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
        }
        <Modal isOpen={this.state.modalIsOpen} className="PreviewModal" overlayClassName="PreviewModalOverlay">
          <h3>
            <span>Document Preview</span>
            <span className="ModalClose" onClick={this.togglePreviewModal}>
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </h3>
          
          {this.state.activeDocument && (
            <PDFViewer document={{ url: this.state.activeDocument.documentLink }} scale={1.5} />
          )}
        </Modal>
      </div>
    );
  }

  togglePreviewModal = (activeDocument = null) => {
    console.log(activeDocument)
    this.setState({ modalIsOpen: !this.state.modalIsOpen, activeDocument });
  }

  clearSearch = () => {
    this.setState({
      errorResult: '',
      documentId: '',
      documentHolder: '',
      documentName: '',
      issueTimestamp: null,
    })
  }

  onDocumentIdChange = (e) => {
    this.setState({
      documentId: e.target.value,
      errorMessage: '',
    })
  }

  onDocumentHolderChange = (e) => {
    this.setState({
      documentHolder: e.target.value,
      errorMessage: '',
    })
  }

  onDocumentNameChange = (e) => {
    this.setState({
      documentName: e.target.value,
      errorMessage: '',
    })
  }

  onIssueDateChange = (date) => {
    this.setState({
      issueTimestamp: date,
      errorMessage: '',
    })
  }

  onNewDocumentClick = () => {
    this.props.history.push('/explorer/new-document');
  }

  onNewTemplateClick = () => {
    this.props.history.push('/explorer/new-template');
  }

  formValidate = () => {
    const { documentId, documentHolder, documentName, issueTimestamp } = this.state;

    if (!documentId && !documentHolder && !documentName && !issueTimestamp) {
      this.setState({
        errorMessage: 'Enter at least one field',
        isLoading: false,
      });

      return false;
    }

    return true;
  }

  onRevoke = (document, documents) => {
    const token = getAuthToken();

    document.isLoading = true;
    this.setState({ documents });

    Api.put(`v1/document/revoke`, { documentID: document.documentId }, token)
      .then(r => {
        document.isRevoked = true;
        document.isLoading = false;
        this.setState({ documents });
      })
      .catch(() => {
        document.isLoading = false;
        this.setState({ documents });
      });
  }
  
  onSubmit = (e) => {
    e.preventDefault();
    const token = getAuthToken();
    const { documentId, documentHolder, documentName, issueTimestamp } = this.state;
    
    this.setState({ isLoading: true, errorResult: '' });

    if (this.formValidate()) {

      console.log(issueTimestamp);

      const data = {
        documentID: documentId || null,
        recipientName: documentHolder || null,
        documentName: documentName || null,
        issueTimestamp: Date.parse(issueTimestamp) || null
      };

      Api.post(`v1/document/search`, data, token)
        .then((responseStructured) => {
          this.setState({
            errorResult: '',
            documents: responseStructured.data,
            isLoading: false,
          });
        
          if (responseStructured.data.length === 0) {
            this.setState({
              errorResult: 'No documents found',
              isLoading: false,
              documents: null,
            });
          }
      }).catch((error) => {
        checkAuth(error.response, this.props.history);
        this.setState({
          errorResult: error.toString(),
          isLoading: false,
          documents: null,
        })
      })
      
    }
  }
}

export default withRouter(InternalSearch);