import React, { Component } from 'react';
import { withRouter } from 'react-router';

import { getAuthToken, logout } from '../../services/utils';
import { Api } from '../../services/api';

import { GlobalContext } from '../../services/context';

import './Profile.scss';

const rePassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

class Profile extends Component {
  static contextType = GlobalContext;

  state = {
    username: '',
    token: getAuthToken(),
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    secret: '',
    role: '',
    enabled2FA: false,
    gacode: '',
    showQRcode: false,
    requiredChangePassword: false,
    password: '',
    confirmPassword: '',
    errorMessage: '',
    loaded: false,
    loading: false
  }

  componentDidMount() {
    this.getProfile()
      .then(profile => {
        this.setState(profile);
        this.setState({ loaded: true });
      });
  }

  handle2FA = () => {
    const { enabled2FA } = this.state;
    this.setProfile({ enabled2FA: !enabled2FA, username: this.state.username })
      .then(profile => {
        if (profile.secret)
          this.setState({ showQRcode: true, secret: profile.secret });
        else
          this.setState({ enabled2FA: false });
      });
  }

  onChangeCode = (e) => {
    const gacode = (e.target.value).replace(/\D/g, "");
    this.setState({ gacode });
  }

  onSubmit = () => {
    this.verify2FA(this.state.gacode)
      .then(() => {
        alert('2FA successfully setted.');
        this.setState({ showQRcode: false, enabled2FA: true, gacode: '' });
      }).catch(() => {
        alert('Error');
      });
  }

  onConfirmPasswordChange = (e) => {
    this.setState({
      confirmPassword: e.target.value,
      errorMessage: '',
    });
  }

  onPasswordChange = (e) => {
    this.setState({
      password: e.target.value,
      errorMessage: '',
    });
  }

  onBlur = (fieldName, e) => {
    this.setState({
      [fieldName]: e.target.value.trim(),
    });
  }

  onPasswordSubmit = (e) => {
    const { password, confirmPassword, username } = this.state;
    if (!password || !confirmPassword || !rePassword.test(password)) {
      this.setState({
        errorMessage: "Password should contain 8+ characters at least one big letter and one digit",
        passwordError: true,
      });
      return;
    }

    if (password !== confirmPassword) {
      this.setState({
        errorMessage: "Passwords do not match",
        passwordError: true,
      });
      return;
    }

    this.setState({ loading: true });

    this.setProfile({ password, username })
      .then(r => {
        this.setState({ requiredChangePassword: false, loading: false });
        alert('Password updated! Now you will be logged off                                    and have to log in for password confirmation');
        logout(this.props.history);

      }).catch(error => {
        this.setState({ loading: false });
        const r = error.response.data;
        if (r.error_code) {
          // if (r.error_code === '40010') {
            alert(r.error_description);
          // }
        }
      });
  }

  // RENDER

  render() {


    const { token, id, username, email, enabled2FA, firstName,
      lastName, role, showQRcode, secret, gacode, requiredChangePassword,
      password, confirmPassword, errorMessage, loaded, loading } = this.state;

    if (!loaded)
      return <p className="text-center"><br /><br /><br /><i className="fa fa-spinner fa-spin" /></p>;

    if (requiredChangePassword)
      return (
        <div className="base profilePage">
          <div className="base__header">
            <h1 className="base__title">Update your password</h1>
          </div>
          <section className="profilePageBlock">
            <div className="profile">
              <input
                className='base__form-input input'
                placeholder="Password" type="password"
                value={password}
                onChange={this.onPasswordChange}
                onBlur={(e) => this.onBlur('password', e)}
              />
              <input
                className="base__form-input input"
                placeholder="Repeat Password" type="password"
                value={confirmPassword}
                onChange={this.onConfirmPasswordChange}
                onBlur={(e) => this.onBlur('confirmPassword', e)}
              />
            </div>
            <div className="profile">
              {!!errorMessage && (
                <p className="red">{errorMessage}</p>
              )}
              <p>
                <button className="base__form-button button" type="button"
                  onClick={this.onPasswordSubmit} disabled={loading}>Submit</button>
              </p>
            </div>
          </section>
        </div>
      )

    if (!showQRcode)
      return (
        <div className="base profilePage">
          <div className="base__header">
            <h1 className="base__title">Profile</h1>
          </div>
          <section className="profilePageBlock Grid">
            <div className="profile">
              <h2 className="profile__title">Username:</h2>
              <div className="profile__token">{this.context.user.username || username}</div>
            </div>
            <div className="profile">
              <h2 className="profile__title">Email:</h2>
              <div className="profile__token">{email}</div>
            </div>
            <div className="profile">
              <h2 className="profile__title">Name:</h2>
              <div className="profile__token">{firstName} {lastName}</div>
            </div>
            <div className="profile">
              <h2 className="profile__title">Role:</h2>
              <div className="profile__token">{role}</div>
            </div>
            <div className="profile">
              <h2 className="profile__title">Auth token:</h2>
              <div className="profile__token">{token}</div>
            </div>
            {!!id && (
              <div className="profile">
                {!!id && (<h2 className="profile__title">Two Factor Auth:</h2>)}
                <div className="profile__token">
                  {!!enabled2FA && (
                    <span>
                      <b>Enabled</b> [<span onClick={this.handle2FA}>Disable</span>]
              </span>
                  )}
                  {!enabled2FA && <span onClick={this.handle2FA}>Enable</span>}
                </div>
              </div>
            )}
          </section>
        </div>
      )
    else
      return (
        <div className="base profilePage">
          <div className="base__header">
            <h1 className="base__title">Set up Two Factor Authentication</h1>
          </div>
          <section className="profilePageBlock">
            <div className="profile">
              <p>Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to log in.In order to enable Two Factor Authentication please install two factor authentication app from Google Play or Appstore </p>
              <img alt="qr" align="middle" src={"https://chart.googleapis.com/chart?cht=qr&chl=" + encodeURIComponent(secret) + "&chs=320x320&chld=L|0"} />
            </div>
            <div className="profile">
              <form onSubmit={this.onSubmit}>
                <br />
                <input className="signin__body-input input" type="text" placeholder="One time authorization code"
                  value={gacode} onChange={this.onChangeCode} />
                <br /><br />
                <button type="submit" className="signin__footer-button button" disabled={gacode.length !== 6}>Submit</button>
              </form>
            </div>
          </section>
        </div>
      )
  }

  // API

  getProfile() {
    return Api.get('v1/profile', getAuthToken())
      .then(r => {
        const { id, email, enabled2FA, firstName, lastName, role, requiredChangePassword } = r.data;
        return { id, email, enabled2FA, firstName, lastName, role, requiredChangePassword };
      });
  }

  setProfile(data) {
    return Api.put('v1/profile', data, getAuthToken())
      .then(r => r.data);
  }

  verify2FA(code) {
    return Api.post('v1/profile/2fa/verify', { code }, getAuthToken())
  }
}

export default withRouter(Profile);
