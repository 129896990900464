import React, { Component, Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Header from '../Header/Header';

import InternalSearch from '../InternalSearch/InternalSearch';
import NewDocument from '../NewDocument/NewDocument';
import NewDocumentCreate from '../NewDocumentCreate/NewDocumentCreate';
import NewNonStructured from '../NewNonStructured/NewNonStructured';
import NewTemplate from '../NewTemplate/NewTemplate';

import Profile from '../Profile/Profile';

import Users from '../Users/Users';
import EditUser from '../EditUser/EditUser'
import CreateUser from '../CreateUser/CreateUser';

import Clients from '../OAuthClients/OAuthClients';
import EditClient from '../EditClient/EditClient'
import CreateClient from '../CreateClient/CreateClient';
// import Customization from '../Customization/Customization';

import { GlobalContext, ROLES } from '../../services/context';


class Protected extends Component {

  static contextType = GlobalContext;
  

  componentDidMount() {
    if (process.env.NODE_ENV === 'development') {
      console.log(this.context.user);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    if (process.env.NODE_ENV === 'development') {
      console.log("ROUTE CHANGED");
    }
    this.context.refreshUser();
  }

  render() {

    const { role, id, requiredChangePassword } = this.context.user;

    if (!id) {
      return <Redirect to="/" />;
    }

    if (requiredChangePassword && window.location.hash !== "#/explorer/profile") {
      return <Redirect to="/explorer/profile" />;
    }

    return (
      <div className="app">
        <Header />
        <div className="app-body">
          <main className="main">
            <section>
              <Switch>
                <Redirect exact from='/explorer' to="/explorer/search-documents" />
                {this.renderRoutes(role)}
              </Switch>
            </section>
          </main>
        </div>
      </div>
    );
  }

  renderRoutes = (role) => {
    switch (role) {
      case ROLES.USER:
        return this.renderUserRoutes();
      case ROLES.EDITOR:
        return this.renderEditorRoutes();
      case ROLES.ADMIN:
        return this.renderAdminRoutes();
      default:
        break;
    }
  }

  renderUserRoutes = () => (
    <Fragment>
      <Route path="/explorer/new-document/structured/:id" component={NewDocumentCreate} />
      <Route path="/explorer/new-document/non-structured" component={NewNonStructured} />
      <Route exact path="/explorer/new-document" component={NewDocument} />
      <Route path="/explorer/search-documents" component={InternalSearch} />
      <Route path="/explorer/profile" component={Profile} />
    </Fragment>
  );

  renderEditorRoutes = () => (
    <Fragment>
      <Route path="/explorer/new-document/structured/:id" component={NewDocumentCreate} />
      <Route path="/explorer/new-document/non-structured" component={NewNonStructured} />
      <Route path="/explorer/new-template" component={NewTemplate} />
      <Route exact path="/explorer/new-document" component={NewDocument} />
      <Route path="/explorer/search-documents" component={InternalSearch} />
      <Route path="/explorer/profile" component={Profile} />
    </Fragment>
  );

  renderAdminRoutes = () => (
    <Fragment>
      <Route path="/explorer/new-document/structured/:id" component={NewDocumentCreate} />
      <Route path="/explorer/new-document/non-structured" component={NewNonStructured} />
      <Route path="/explorer/new-template" component={NewTemplate} />
      <Route exact path="/explorer/new-document" component={NewDocument} />
      <Route path="/explorer/search-documents" component={InternalSearch} />
      <Route path="/explorer/profile" component={Profile} />
      <Route exact path="/explorer/users" component={Users} />
      <Route exact path="/explorer/clients" component={Clients} />
      <Route path="/explorer/users/create-user" component={CreateUser} />
      <Route path="/explorer/users/edit-user/:id" component={EditUser} />
      <Route path="/explorer/clients/create-client" component={CreateClient} />
      <Route path="/explorer/clients/edit-client/:id" component={EditClient} />
      {/* <Route path="/explorer/customization" component={Customization} /> */}
    </Fragment>
  )
}

export default Protected;
