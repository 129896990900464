import axios from 'axios';
import qs from 'qs';

import { refreshProfile, getRefreshToken, updateTokens, logout } from './utils';

let retry = false;

let BASE_URL = `https://api-${window.location.hostname}`;

if (process.env.NODE_ENV === 'development') {
  BASE_URL = 'https://api-issuer.b1.cronica.pro';
}

axios.interceptors.response.use(response => {
  refreshProfile();
  return response;
}, error => {

  const originalRequest = error.config;
  const refreshToken = getRefreshToken();
  
  if (error.response.status === 403) {
    alert('Server Error');
  }

    if (error.response.status === 401 && !retry && !!refreshToken) {

    originalRequest._retry = true; 
    retry = true;
    
    return postFormData('oauth/token', { grant_type: 'refresh_token', refresh_token: refreshToken })
      .then(({ data }) => {
        updateTokens(data.access_token, data.refresh_token);

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
        originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token;

        return axios(originalRequest);
      }).catch(error => {
        console.log(error);
        logout(true);
      });
  }

  return Promise.reject(error);
});

const get = (path, token) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  return (
    axios({
      headers,
      method: 'get',
      url: `${BASE_URL}/${path}`,
    })
  );
}

const post = (path, body, token) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  return (
    axios({
      headers,
      method: 'post',
      url: `${BASE_URL}/${path}`,
      data: body ? JSON.stringify(body) : undefined,
    })
  );
}

const postFormData = (path, body, token) => {
  return axios({
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `${token ? `Bearer ${token}` : 'Basic dHJ1c3RlZGNsaWVudGlkOktBOW9uRXBhODFVcTlhb3E3QnpnOTFiZElkYVlkMQ=='}`,
    },
    method: 'post',
    url: `${BASE_URL}/${path}`,
    data: qs.stringify(body),
  });
};

const postMultipartFormData = (path, formData, token) => {
  return axios({
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `${token ? `Bearer ${token}` : 'Basic dHJ1c3RlZGNsaWVudGlkOktBOW9uRXBhODFVcTlhb3E3QnpnOTFiZElkYVlkMQ=='}`,
    },
    method: 'post',
    url: `${BASE_URL}/${path}`,
    data: formData,
  })
}

const put = (path, body, token) => (
  axios({
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'put',
    url: `${BASE_URL}/${path}`,
    data: body ? JSON.stringify(body) : undefined,
  })
);

const deleteReq = (path, token) => (
  axios({
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'delete',
    url: `${BASE_URL}/${path}`,
  })
)

export const Api = {
  get,
  post,
  put,
  deleteReq,
  postFormData,
  postMultipartFormData,
  BASE_URL,
};
