import React, { Component } from 'react';
import { withRouter } from 'react-router';

import { Api } from '../../services/api';
import { getAuthToken, checkAuth } from '../../services/utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons'

import { CustomInput } from '../CustomInput/CustomInput';

import './CreateClient.scss';

const SCOPE = {
  READ: 'read',
  WRITE: 'write',
};

const TYPES = {
  PASSWORD: 'password',
  TOKEN: 'refresh_token',
};

class CreateUser extends Component {
  state = {
    clientId: '',
    scope: SCOPE.READ,
    grantType: TYPES.PASSWORD,
    clientIdError: '',
    isLoading: false,
    errorMessage: '',
    successMessage: '',
  }

  render() {
    const { clientId, isLoading, scope, grantType, clientIdError, errorMessage, successMessage } = this.state;

    return (
      <div>
        <div className="base">
          <div className="base__header">
            <div className="base__header-wrapper">
              <FontAwesomeIcon icon={faArrowLeft} onClick={this.onBackClick} />
              &nbsp;&nbsp;&nbsp;
              <h1 className="base__title">Create Client</h1>
            </div>
          </div>
          <form className="base__form" onSubmit={this.onSubmit}>
            {(clientIdError) &&
              <div className="createuser__error error">
                <label className="createuser__error-label error__label">{errorMessage}</label>
              </div>
            }
            {successMessage && <div className="success-message">{successMessage}</div>}

            <section className="edit-user">
              <h3 className="fullcell">Client Details</h3>
              <div className="fullcell">
                <CustomInput value={clientId} placeholder="Client Id" onChange={this.onClientIdChange} />
              </div>

              <ul className="radio-list">
                <li >
                  Scope:
                </li>
                <li className={'radio-item' + (scope === SCOPE.READ ? ' radio-item_active' : '')} onClick={this.onChangeScope(SCOPE.READ)}>
                  READ
                </li>
                <li className={'radio-item' + (scope === SCOPE.WRITE ? ' radio-item_active' : '')} onClick={this.onChangeScope(SCOPE.WRITE)}>
                  WRITE
                </li>
              </ul>

              <ul className="radio-list">
                <li>Grant Types:</li>
                <li className={'radio-item' + (grantType === TYPES.PASSWORD ? ' radio-item_active' : '')} onClick={this.onChangeGType(TYPES.PASSWORD)}>
                  PASSWORD
                </li>
                <li className={'radio-item' + (grantType === TYPES.TOKEN ? ' radio-item_active' : '')} onClick={this.onChangeGType(TYPES.TOKEN)}>
                  TOKEN
                </li>
              </ul>
            </section>

            <p className="text-center">
              <button className="button" type="submit" disabled={isLoading}>
                {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
                {!isLoading && 'Create'}
              </button>
            </p>
          </form>
        </div>
      </div>
    );
  }

  

  onSubmit = (e) => {
    e.preventDefault();

    const {
      clientId, scope, grantType
    } = this.state;

    const authToken = getAuthToken();
    
    if (this.formValidate()) {
      this.setState({
        isLoading: true,
      })

      Api.post(`v1/oauth2/client`, {
        clientId,
        scope,
        grantTypes: grantType,
      }, authToken).then((response) => {
        this.setState({
          successMessage: response.data.clientId + ' created'
        })

        this.props.history.push('/explorer/clients');

        setTimeout(() => {
          this.setState({
            successMessage: '',
            isLoading: false,
            clientId: '',
            scope: SCOPE.READ,
            grantType: TYPES.PASSWORD,
          });
        }, 2000)

      }).catch((error) => {
        checkAuth(error.response, this.props.history);
        this.setState({
          isLoading: false,
        })
        if (error.response && error.response.data && error.response.status === 422) {
          this.setState({
            clientIdError: true,
            errorMessage: error.response.data,
          })
        }
      })
    }
  }

  formValidate = () => {
    const { clientId } = this.state;

    let isValid = true;

    if (!clientId) {
      this.setState({
        errorMessage: "Form has invalid data",
        clientIdError: true,
      });

      isValid = false;
    }

    return isValid;
  }

  onBackClick = () => {
    this.props.history.push('/explorer/clients');
  }

  onChangeScope = (scope) => () => {
    this.setState({
      scope,
    })
  }
  
  onChangeGType = (grantType) => () => {
    this.setState({
      grantType,
    })
  }

  onChangeGrantTypes = (grantTypes) => () => {
    this.setState({
      grantTypes,
    })
  }

  onClientIdChange = (e) => {
    this.setState({
      clientId: e.target.value,
      clientIdError: false,
    });
  }

  onBlur = (fieldName, e) => {
    this.setState({
      [fieldName]: e.target.value.trim(),
    });
  }
}

export default withRouter(CreateUser);
