import React, {Component} from 'react';
import { withRouter } from 'react-router';

import { Api } from '../../services/api';
import { getAuthToken, checkAuth } from '../../services/utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons'

import { CustomInput } from '../CustomInput/CustomInput';

import './CreateUser.scss';

const ROLES = {
  USER: 'USER',
  EDITOR: 'EDITOR',
  ADMIN: 'ADMIN',
};

class CreateUser extends Component {
  state = {
    username: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    email: '',
    role: ROLES.USER,
    usernameError: '',
    passwordError: '',
    confirmPasswordError: '',
    firstNameError: '',
    lastNameError: '',
    emailError: '',
    isLoading: false,
    errorMessage: '',
    successMessage: '',
  }

  render() {
    const {
      username, password, firstName, lastName, email, isLoading, role, usernameError, confirmPassword,
      confirmPasswordError, passwordError, firstNameError, lastNameError, emailError, errorMessage, successMessage,
    } = this.state;
    return (
      <div>
        <div className="base">
          <div className="base__header">
            <div className="base__header-wrapper">
              <FontAwesomeIcon icon={faArrowLeft} onClick={this.onBackClick} />
              &nbsp;&nbsp;&nbsp;
              <h1 className="base__title">Create User</h1>
            </div>
          </div>
          <form className="base__form" onSubmit={this.onSubmit}>
            {(usernameError || passwordError || confirmPasswordError || firstNameError || lastNameError || emailError) &&
              <div className="createuser__error error">
                <label className="createuser__error-label error__label">{errorMessage}</label>
              </div>
            }
            {successMessage && <div className="success-message">{successMessage}</div>}

            <section className="edit-user">
              <h3 className="fullcell">User Details</h3>
              <div className="fullcell">
                <CustomInput value={username} onChange={this.onUsernameChange} placeholder="UserName" isError={usernameError} />
              </div>
              <CustomInput value={firstName} onChange={this.onFirstNameChange} placeholder="First Name" isError={firstNameError} />
              <CustomInput value={lastName} onChange={this.onLastNameChange} placeholder="Last Name" isError={lastNameError} />
              <div className="fullcell">
                <CustomInput value={email} onChange={this.onEmailChange} placeholder="Email" isError={emailError} />
              </div>
              <CustomInput type="password" value={password} onChange={this.onPasswordChange} placeholder="Password" isError={passwordError} />
              <CustomInput type="password" value={confirmPassword} onChange={this.onConfirmPasswordChange} placeholder="Repeat Password" isError={passwordError} />
            
              <ul className="radio-list">
                <li className={'radio-item' + (role === ROLES.USER ? ' radio-item_active' : '')} onClick={this.onChangeRole(ROLES.USER)}>
                  USER
              </li>
                <li className={'radio-item' + (role === ROLES.EDITOR ? ' radio-item_active' : '')} onClick={this.onChangeRole(ROLES.EDITOR)}>
                  EDITOR
              </li>
                <li className={'radio-item' + (role === ROLES.ADMIN ? ' radio-item_active' : '')} onClick={this.onChangeRole(ROLES.ADMIN)}>
                  ADMIN
              </li>
              </ul>
            
            </section>

            <p className="text-center">
              <button className="button" type="submit" disabled={isLoading}>
                {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
                {!isLoading && 'Create'}
              </button>
            </p>
             
          </form>
        </div>
      </div>
    );
  }

  onSubmit = (e) => {
    e.preventDefault();
    const authToken = getAuthToken();
    const {
      username, password, firstName, lastName, email, role,
    } = this.state;

    if (this.formValidate()) {
      this.setState({
        isLoading: true,
      })

      Api.post(`v1/user`, {
        username,
        password,
        firstName,
        lastName,
        email,
        role,
      }, authToken).then((response) => {
        this.setState({
          successMessage: response.data.username + ' created'
        })

        this.props.history.push('/explorer/users');

        setTimeout(() => {
          this.setState({
            successMessage: '',
            isLoading: false,
            username: '',
            password: '',
            confirmPassword: '',
            firstName: '',
            lastName: '',
            email: '',
            role: ROLES.USER,
          });
        }, 2000)
        
      }).catch((error) => {
        checkAuth(error.response, this.props.history);
        this.setState({
          isLoading: false,
        })
        if (error.response && error.response.data && error.response.status === 422) {
          this.setState({
            usernameError: true,
            errorMessage: error.response.data,
          })
        }
      })  
    }
  }

  formValidate = () => {
    const {
      username, password, firstName, lastName, email, confirmPassword,
    } = this.state;

    const rePassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[$&+,:;=?@#|'<>.^*()%!-]).{8,}$/;
    const reEmail = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

    let isValid = true;

    if (!username) {
      this.setState({
        errorMessage: "Form has invalid data",
        usernameError: true,
      });

      isValid = false;
    }

    if (!password) {
      this.setState({
        errorMessage: "Form has invalid data",
        passwordError: true,
      });

      isValid = false;
    }

    if (!firstName) {
      this.setState({
        errorMessage: "Form has invalid data",
        firstNameError: true,
      });

      isValid = false;
    }

    if (!lastName) {
      this.setState({
        errorMessage: "Form has invalid data",
        lastNameError: true,
      });

      isValid = false;
    }

    if (!email) {
      this.setState({
        errorMessage: "Form has invalid data",
        emailError: true,
      });

      isValid = false;
    }

    if (!reEmail.test(email)){
      this.setState({
        errorMessage: "Email is not valid",
        emailError: true,
      })

      isValid = false;
    }

    if (!rePassword.test(password)) {
      this.setState({
        errorMessage: "Password should contain 8+ characters at least one big letter and one digit",
        passwordError: true,
      });

      isValid = false;
    }

    if (password !== confirmPassword) {
      this.setState({
        errorMessage: "Passwords do not match",
        passwordError: true,
        confirmPasswordError: true,
      })
      isValid = false;
    }

    return isValid;
  }

  onBackClick = () => {
    this.props.history.push('/explorer/users');
  }

  onChangeRole = (role) => () => {
    this.setState({
      role,
    })
  }

  onEmailChange = (e) => {
    this.setState({
      email: e.target.value,
      emailError: false,
    });
  }

  onUsernameChange = (e) => {
    this.setState({
      username: e.target.value,
      usernameError: false,
    });
  }

  onConfirmPasswordChange = (e) => {
    this.setState({
      confirmPassword: e.target.value,
      confirmPasswordError: false,
    });
  }

  onPasswordChange = (e) => {
    this.setState({
      password: e.target.value,
      passwordError: false,
    });
  }

  onFirstNameChange = (e) => {
    this.setState({
      firstName: e.target.value,
      firstNameError: false,
    });
  }

  onLastNameChange = (e) => {
    this.setState({
      lastName: e.target.value,
      lastNameError: false,
    });
  }


  onBlur = (fieldName, e) => {
    this.setState({
      [fieldName]: e.target.value.trim(),
    });
  }
}

export default withRouter(CreateUser);
