import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';

import Select from 'react-select';

import { DateInput, CustomInput } from '../CustomInput/CustomInput';

import ResultForm from '../ResultForm/ResultForm';

import { Api } from '../../services/api';
import { getAuthToken, checkAuth } from '../../services/utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';

import './NewDocumentCreate.scss';

const ACCESS_TYPE = {
  PRIVATE: 'private',
  PUBLIC: 'public',
  LIMITED: 'limited',
}

class NewDocumentCreate extends Component {
  state = {
    banks: null,
    document: null,
    limitedTo: [],
    accessType: ACCESS_TYPE.PRIVATE,
    expirationDate: null,
    documentHolder: '',
    isLoading: false,
    documentHolderError: false,
    limitedToError: false,
    items: null,
    template: null,
    errorMessage: '',
  }

  componentDidMount() {
    const authToken = getAuthToken();
    Api.get('v1/banks', authToken).then((response) => {
      const banks = response.data.map(bank => ({
        value: bank.publicKey,
        label: bank.bankName,
      }));
      this.setState({
        banks,
      });
    }).catch((error) => {
      checkAuth(error.response, this.props.history);
    });

    Api.get(`v1/template/${this.props.match.params.id}`, authToken).then((response) => {
      const items = response.data.items || {};

      items.fields.forEach(field => {
          field.value = '';
        })
        if (items.tables) {
          items.tables.forEach(table => {
            table.rows = [Array.from(Array(table.columns.length), () => '')];
          });
        }
      this.setState({
        template: response.data || {},
        items
      });

    }).catch((error) => {
      checkAuth(error.response, this.props.history);
    });
  }

  render() {
    const {
      limitedTo, banks, document, accessType, expirationDate, documentHolder, isLoading,
      documentHolderError, limitedToError, errorMessage, items, template,
    } = this.state;

    return (
      <Fragment>
        {document && <ResultForm document={document} />}
        {!document && (
          <div className="base">
            <div className="base__header">
              <div className="base__header-wrapper">
                <FontAwesomeIcon icon={faArrowLeft} onClick={this.goBack} />
                &nbsp;&nbsp;&nbsp;
                {template && <h1 className="base__title">{`Issue ${template.name}`}</h1>}
              </div>
            </div>
            <form className="base__form" onSubmit={this.onSubmit}>
              {(documentHolderError || limitedToError) &&
                <div className="nonstructured__error error">
                  <label className="nonstructured__error-label error__label">{errorMessage}</label>
                </div>
              }
              <section className="edit-document">

                <h3 className="fullcell">Document details</h3>

                <CustomInput 
                  placeholder="Document Holder" 
                  value={documentHolder} 
                  onChange={this.onDocumentHolderChange} 
                  isError={documentHolderError} />
                <DateInput
                  selected={expirationDate}
                  onChange={this.handleExpirationDateChange}
                  placeholder="Expiration Date (optional)"
                  minDate={new Date(Date.now())} />

                <ul className="radio-list">
                  <li className={'radio-item ' + (accessType === ACCESS_TYPE.PRIVATE ? 'radio-item_active' : '')}
                    onClick={this.onChangeAccessType(ACCESS_TYPE.PRIVATE)}>
                    Private
                  </li>
                  <li className={'radio-item ' + (accessType === ACCESS_TYPE.PUBLIC ? 'radio-item_active' : '')}
                    onClick={this.onChangeAccessType(ACCESS_TYPE.PUBLIC)}>
                    Public
                  </li>
                  <li className={'radio-item ' + (accessType === ACCESS_TYPE.LIMITED ? 'radio-item_active' : '')}
                    onClick={this.onChangeAccessType(ACCESS_TYPE.LIMITED)}>
                    Limited
                  </li>
                </ul>

                <div>
                  {accessType === ACCESS_TYPE.LIMITED && (<p>Select bank...</p>)}
                  {accessType === ACCESS_TYPE.LIMITED && (
                    <Select className={(limitedToError ? 'select_error' : '') + ' input'}
                      value={limitedTo} onChange={this.handleMultiselect}
                      multi options={banks} clearable={false}
                    />
                  )}
                </div>

                <h3 className="fullcell">Fields</h3>

                {items && items.fields.map((field, i) => (
                  <CustomInput key={field.systemName + i}
                    placeholder={field.name} 
                    value={field.value} 
                    onChange={(e) => this.onChangeItem(field.systemName, i, e.target.value)}  />
                ))}

              </section>

              <section className="edit-document-table">
                {items && items.tables && items.tables.map((table, i) => (
                  <div key={table.systemTableName + i} >
                    <h3>{table.tableName}</h3>
                    {table.rows && table.rows.map((row, r) => (
                      <div key={r} className="edit-document-table-row">
                        <p>Row {r+1}</p>
                        <section className="edit-document-table-row-grid">
                        {table.columns.map((column, c) => (
                          <CustomInput key={column.columnName + c}
                            placeholder={column.columnName} value={row[c]}
                            onChange={(e) => this.onChangeColumn(i, r, c, e.target.value)} />
                        ))}
                        </section>
                      </div>
                    ))}

                    <button className="button add-row-btn" type="button" disabled={isLoading} onClick={(e) => this.onAddRow(i, e)}>
                      New Row
                    </button>

                  </div>
                ))}
              </section>

              <p className="text-center">
                <button className="button" type="submit" disabled={isLoading}>
                  {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
                  {!isLoading && 'Submit'}
                </button>
              </p>
                
            </form>
          </div>
        )}
      </Fragment>
    );
  }

  onChangeItem = (systemName, i, value) => {
    const items = this.state.items;
    items.fields[i].value = value;
    this.setState({
      items
    })
  }

  onChangeColumn = (i, row, column, value) => {
    const items = this.state.items;
    const table = items.tables[i];
    table.rows[row][column] = value;
    this.setState({
      items
    });
  }

  onAddRow(i, e) {
    const items = this.state.items;
    const table = items.tables[i];
    table.rows.push(Array.from(Array(table.columns.length), () => ''));
    this.setState({
      items
    });
  }

  handleMultiselect = (selectedOption) => {
    this.setState({
      limitedTo: selectedOption,
      limitedToError: false,
    });
  }

  handleExpirationDateChange = (date) => {
    this.setState({
      expirationDate: date
    });
  }

  onDocumentHolderChange = (e) => {
    this.setState({
      documentHolder: e.target.value,
      documentHolderError: false,
    });
  }

  onChangeAccessType = (accessType) => () => {
    this.setState({
      accessType,
    })
  }

  onBlur = (fieldName, e) => {
    this.setState({
      [fieldName]: e.target.value.trim(),
    });
  }

  goBack = () => {
    this.props.history.goBack();
  }

  formValidate = () => {
    const { accessType, documentHolder, limitedTo } = this.state;

    let isValid = true;

    if (accessType === ACCESS_TYPE.LIMITED && limitedTo.length === 0) {
      this.setState({
        errorMessage: "Form has invalid data",
        limitedToError: true,
      });

      isValid = false;
    }

    if (!documentHolder) {
      this.setState({
        errorMessage: "Form has invalid data",
        documentHolderError: true,
      });

      isValid = false;
    }

    return isValid;
  }

  onSubmit = (e) => {
    e.preventDefault();
    const authToken = getAuthToken();
    const {
      accessType, expirationDate, template, documentHolder, limitedTo: rawLimitedTo
    } = this.state;

    if (this.formValidate()) {
      const issueTimestamp = Date.now();
      // currDate.setHours(0, 0, 0, 0);
      this.setState({
        isLoading: true,
      })
      let limitedTo = [];
      if (accessType === ACCESS_TYPE.LIMITED) {
        limitedTo = rawLimitedTo.map(item => item.value);
      }
      if (accessType === ACCESS_TYPE.PUBLIC) {
        limitedTo = ['public'];
      }

      const rawData = {fields: {}, tables: {}};
      this.state.items.fields.forEach(field => {
        rawData.fields[field.systemName] = field.value;
      });
      this.state.items.tables.forEach(table => {
        rawData.tables[table.systemTableName] = table.rows;
      });

      Api.post(`v1/document/${this.props.match.params.id}`, {
        documentName: template.name,
        recipientName: documentHolder,
        issueTimestamp,
        expireTimestamp: expirationDate ? Date.parse(expirationDate) : null,
        limitedTo: limitedTo,
        dataJson: rawData
      }, authToken).then((response) => {
        this.setState({
          submited: true,
          document: response.data,
          isLoading: false,
        });
      }).catch((error) => {
        checkAuth(error.response, this.props.history);
        this.setState({
          errorMessage: error || 'Server Erorr',
          isLoading: false,
        })
      })
    }
  }

}

export default withRouter(NewDocumentCreate);
