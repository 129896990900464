import React, {Component} from 'react';
import { withRouter } from 'react-router';

import { Api } from '../../services/api';
import { getAuthToken, checkAuth } from '../../services/utils';

import logo from '../../assets/svg/add-document.svg';
import './NewDocument.scss';

class NewDocument extends Component {
  state = {
    listOfTemplates: [],
    rawListOfTemplates: [],
    searchString: '',
  }

  componentDidMount() {
    Api.get('v1/templates', getAuthToken()).then((response) => {
      this.setState({
        listOfTemplates: response.data,
        rawListOfTemplates: response.data
      });
      if (!response.data) {
        this.setState({
          error: 'Templates are not found',
        });
      }
    }).catch((error) => {
      checkAuth(error.response, this.props.history);
      this.setState({
        isLoading: false,
        error: 'Templates are not found',
      });
    })
  }

  render() {
    const { listOfTemplates, searchString } = this.state; 
    return (
      <div className="base new-document">
        <div className="base__header">
          <h1 className="base__title_img text-center">
            <img src={logo} alt="logo" />
          </h1>
          <h1 className="base__title text-center">Issue the Document</h1>
        </div>
        <div className="">
          <p className="text-center text-title">
            Select document from template or<br />
            choose <i onClick={() => this.onDocumentClick('non-structured')}>Generic Document</i></p>
          <ul className="new-document__docs">
          {!searchString && (
            <li
              key="template-generic"
              className="new-document__docs-item"
              onClick={() => this.onDocumentClick('non-structured')}
            >
              <div className="new-document__docs-list"></div>
              <div className="new-document__docs-text">
                  <span>Generic Document</span>
              </div>
            </li>
          )}
          {listOfTemplates.map(template => (
            <li
            key={`template-${template.templateId}`}
            className="new-document__docs-item"
            onClick={() => this.onDocumentClick(`structured/${template.templateId}`)}
            >
              <div className="new-document__docs-list"></div>
              <div className="new-document__docs-text">
                <span>{template.name}</span>
              </div>
            </li>
          ))}
          </ul>
        </div>
        {/*<button className="new-document__button button" onClick={this.onNextClick}>Next →</button>*/}
      </div>
    );
  }

  onDocumentClick = (id) => {
    this.props.history.push(`/explorer/new-document/${id}`);
  }

  onSearchStringChange = (event) => {
    const { rawListOfTemplates } = this.state;
    const searchString = event.target.value.toLowerCase();
    const listOfTemplates = rawListOfTemplates.filter(template => {
      return template.name.toLowerCase().includes(searchString);
    });
    this.setState({ searchString, listOfTemplates });
  }
}

export default withRouter(NewDocument);