import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { getAuthToken, checkAuth } from '../../services/utils';
import { Api } from '../../services/api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import './Users.scss';

class Users extends Component {

  state = {
    users: [],
    searchString: ['', '', '', ''],
  }

  rawUsersList = [];
  fields = { username: 0, firstName: 1, lastName: 2, email: 3 };

  componentDidMount() {
    const authToken = getAuthToken();
    Api.get('v1/users', authToken).then((response) => {
      this.rawUsersList = response.data;
      this.setState({ users: response.data });
      this.props.actions.getUsers(response.data);
    }).catch((error) => {
      checkAuth(error.response, this.props.history);
    });
  }

  onSearchStringChange = (event, value) => {
    const { searchString } = this.state;
    const searchSubString = event.target.value.toLowerCase();

    searchString[value] = searchSubString;

    const users = this.rawUsersList.filter(user => {
      return user.username.toLowerCase().includes(searchString[0])
        && user.firstName.toLowerCase().includes(searchString[1])
        && user.lastName.toLowerCase().includes(searchString[2])
        && user.email.toLowerCase().includes(searchString[3]);
    });

    this.setState({ searchString, users });
  }

  render() {
    const { users, searchString } = this.state;

    return (
      <div>
        <div className="base">
          <div className="base__header">
            <div className="base__header-wrapper">
              <h1 className="base__title">Users</h1>
            </div>
            <Link to="/explorer/users/create-user" className="button">Add new user</Link>
          </div>
          {users && (
            <section className="table-wrapper users-list">
            <table className="table">
              <thead>
                <tr>
                  <th width="160px">ID</th>
                  <th width="200px">Username</th>
                  <th width="200px">First Name</th>
                  <th width="200px">Last Name</th>
                  <th width="200px">Email</th>
                  <th width="">Role</th>
                  <th></th>
                </tr>
                <tr>
                  <th></th>
                  <th>
                    <input className="input" type="text" placeholder="search by username"
                           value={searchString[0]} onChange={(e) => this.onSearchStringChange(e, this.fields.username)} />
                  </th>
                  <th>
                    <input
                      className="base__form-input input searchString"
                      type="text"
                      placeholder="search by firstname"
                      value={searchString[1]}
                      onChange={(e) => this.onSearchStringChange(e, this.fields.firstName)}
                    />
                  </th>
                  <th>
                    <input
                      className="base__form-input input searchString"
                      type="text"
                      placeholder="search by lastname"
                      value={searchString[2]}
                      onChange={(e) => this.onSearchStringChange(e, this.fields.lastName)}
                    /></th>
                  <th>
                    <input
                      className="base__form-input input searchString"
                      type="text"
                      placeholder="search by email"
                      value={searchString[3]}
                      onChange={(e) => this.onSearchStringChange(e, this.fields.email)}
                    />
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {users.map(user => (
                  <tr key={user.username}>
                    <td>{user.id}</td>
                    <td>{user.username}</td>
                    <td>{user.firstName || '-'}</td>
                    <td>{user.lastName || '-'}</td>
                    <td>{user.email || '-'}</td>
                    <td>{user.role}</td>
                    <td>
                      <Link to={`/explorer/users/edit-user/${user.id}`} className="link-button">
                        <FontAwesomeIcon icon={faEdit} /> Edit
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </section>
          )}
        </div>
      </div>
    );
  }
}

export default Users;
