import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import { Api } from '../../services/api';
import { getAuthToken, checkAuth } from '../../services/utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import './OAuthClients.scss';

class OAuthClients extends Component {

  state = { 
    clients: [], 
    searchString: '',
  };

  rawClientsList = [];
  blocked = false;

  componentDidMount() {
    const authToken = getAuthToken();
    Api.get('v1/oauth2/clients', authToken).then((response) => {
      // this.props.actions.getClients(response.data);
      this.rawClientsList = response.data;
      this.setState({ clients: response.data });
    }).catch((error) => {
      checkAuth(error.response, this.props.history);
    });
  }

  componentWillReceiveProps(nextProps) {
    // console.log(this.state, this.props, nextProps);
  }

  render() {
    const { clients, searchString } = this.state;

    return (
      <div>
        <div className="base">
          <div className="base__header">
            <div className="base__header-wrapper">
              <h1 className="base__title">OAuth Clients</h1>
            </div>
            <Link to="/explorer/clients/create-client" className="button">Add new</Link>
          </div>
          {clients && (
            <section className="table-wrapper users-list">
            <table className="table">
              <thead>
                <tr>
                  <th width="200px">ID</th>
                  <th width="660px" className="hiddenMobile">Client Secret</th>
                  <th width="200px">Grant Types</th>
                  <th width=""></th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th>
                    <input 
                      className="base__form-input input searchString"
                      type="text"
                      placeholder="quick search"
                      value={searchString}
                      onChange={this.onSearchStringChange}
                    />
                  </th>
                  <th className="hiddenMobile"></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {clients.map(client => (
                  <tr key={client.clientId}>
                    <td>{client.clientId}</td>
                    <td className="hiddenMobile">{client.clientSecret || '-'}</td>
                    <td>{client.grantTypes || '-'}</td>
                    <td>
                      <Link to={`/explorer/clients/edit-client/${client.clientId}`} className="link-button">
                        <FontAwesomeIcon icon={faEdit} /> Edit
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </section>
          )}
        </div>
      </div>
    );
  }

  onSearchStringChange = (event) => {
    const searchString = event.target.value;
    const clients = this.rawClientsList.filter(client => {
      return client.clientId.toLowerCase().includes(searchString.toLowerCase());
    });
    this.setState({ searchString, clients });
  }

  onDelete = (client, clients) => {
    if (this.blocked)
      return;

    // console.log(this.state);
    // const cl = clients.find(c => c.clientId === client.clientId);

    this.blocked = true;
    const authToken = getAuthToken();
    client.isLoading = true;

    this.setState({ clients });


    Api.deleteReq(`v1/oauth2/client/${client.clientId}`, authToken)
    .then(r => {
      client.isLoading = false;
      this.blocked = false;
      
      const idx = clients.findIndex(c => c.clientId === client.clientId);
      clients.splice(idx, 1);

      this.setState({ clients });
    })
    .catch(() => {
      client.isLoading = false;
      this.blocked = false;

      this.setState({ clients });
    });
    return false;
  }
}

export default OAuthClients;
