import React, { Component } from 'react';
import { withRouter } from 'react-router';

import { Api } from '../../services/api';
import { getAuthToken, checkAuth } from '../../services/utils';

import { CustomInput, CustomInputFile } from '../CustomInput/CustomInput';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'


import './NewTemplate.scss';

import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-editor-decoupled/src/decouplededitor.js';

import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import List from '@ckeditor/ckeditor5-list/src/list';
import Table from '@ckeditor/ckeditor5-table/src/table';

// import TableUtils from '@ckeditor/ckeditor5-table/src/tableutils';
import insertCustomTable from './plugins/table';
import insertVariable from './plugins/variable';
import insertImage64 from './plugins/image64';
import insertFont64 from './plugins/font64';

import CkeModal from './modal';

const TYPE = {
  HTML: 'html',
  CKE: 'cke',
  JASPER: 'jasper',
};

function htmlData(styleData, bodyData) {
  const htmlBody = `
    <html>
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
        <style type="text/css">${styleData}</style>
      </head>
      <body>${bodyData}</body>
    </html>`;
  return htmlBody;
}

const temp = `<p>Hello this is Template constructor</p>`;
let stylesHTML = `table {border-collapse: collapse} td {border: 1px solid silver} .image-style-align-right {float: right} .image-style-align-left {float: left}`;

class NewTemplate extends Component {

  customFonts = [
    "default",
    "Arial, Helvetica, sans-serif",
    "Courier New, Courier, monospace",
    "Georgia, serif",
    "Lucida Sans Unicode, Lucida Grande, sans-serif",
    "Tahoma, Geneva, sans-serif",
    "Times New Roman, Times, serif",
    "Trebuchet MS, Helvetica, sans-serif",
    "Verdana, Geneva, sans-serif"
  ];

  state = {
    id: null,
    templateName: '',
    templateNameError: false,
    isLoading: false,
    metaData: [{
      name: '',
      systemName: '',
    }],
    tables: [{
      tableName: '',
      systemTableName: '',
      columns: [{
        columnName: ''
      }]
    }],
    metaDataError: false,
    tablesError: false,
    file: null,
    fileError: false,
    errorMessage: '',
    successMessage: '',
    type: TYPE.JASPER,
    fileUploaded: false,
    htmlTemplate: temp
  }

  reloadCKE() {
    this.setState({ type: TYPE.JASPER });
    this.setState({ type: TYPE.CKE });
  }

  componentDidMount() {
    window.addEventListener('updateEditor', evt => {
      const fontName = evt.detail.modalResult.fileName.split('.')[0];
      const fontData = evt.detail.modalResult.file64.replace('data:;base64', 'data:application/octet-stream;base64');
      if (this.customFonts.indexOf(fontName) > -1)
        return;
      this.customFonts = evt.detail.options;
      this.reloadCKE();
      stylesHTML += `@font-face { font-family: '${fontName}'; src: url(${fontData}); font-weight: normal; font-style: normal; }`;
    });
  }

  render() {
    const {
      isLoading, templateName, templateNameError, type, fileUploaded, metaDataError, tablesError, 
      metaData, tables, fileError, errorMessage, successMessage, htmlTemplate
    } = this.state;
    return (
      <div className="base newtemplate">
        <div className="base__header">
          <h1 className="base__title">New Template</h1>
        </div>

        <CkeModal />

        <form className="base__form base__form_auto" onSubmit={this.onSubmit}>
          {(templateNameError || metaDataError || fileError) &&
            <div className="newtemplate__error error">
              <label className="newtemplate__error-label error__label">{errorMessage}</label>
            </div>
          }
          {successMessage && <div className="success-message">{successMessage}</div>}

          <section className=" edit-document">
            <h3 className="fullcell">Template Details</h3>

            <div className="fullcell">
              <CustomInput placeholder="Template Name"
                value={templateName} onChange={this.onTemplateNameChange}
                isError={templateNameError} disabled={isLoading} />
            </div>

            <div className="fullcell">
              <ul className="radio-list">
                <li>Template Type: &nbsp;&nbsp;&nbsp;&nbsp;</li>
                <li className={'radio-item ' + (type === TYPE.JASPER ? 'radio-item_active' : '')} onClick={this.onChangeType(TYPE.JASPER)}>
                  JasperSoft
                </li>
                <li className={'radio-item ' + (type === TYPE.HTML ? 'radio-item_active' : '')} onClick={this.onChangeType(TYPE.HTML)}>
                  HTML
                </li>
                <li className={'radio-item ' + (type === TYPE.CKE ? 'radio-item_active' : '')} onClick={this.onChangeType(TYPE.CKE)}>
                  Constructor
                </li>
              </ul>
            </div>

            <div className="fullcell">
              {(type !== TYPE.CKE &&
                <CustomInputFile placeholder="Attach File" onChange={this.onFileChange} accept=".zip" />
              )}
            </div>
          </section>

          {(type === TYPE.JASPER || fileUploaded) &&
            <section className=" edit-document">
              <h3 className="fullcell">Variables</h3>

              {metaData.map((item, i) => (
                <div className="newtemplate__metaData fullcell" key={`mataData-${i}`}>
                  <div className="newtemplate__metaData-inputs">
                    <CustomInput placeholder="Name"
                      value={item.name} onChange={(e) => this.onMetadataNameChange(i, e)}
                      isError={item === '' && metaDataError} disabled={isLoading} />
                    <CustomInput placeholder="System Name"
                      value={item.systemName} onChange={(e) => this.onMetadataSystemNameChange(i, e)}
                      isError={item === '' && metaDataError} disabled={type === TYPE.HTML || type === TYPE.CKE} />
                  </div>

                  {type === TYPE.JASPER &&
                    <div className="newtemplate__metaData-buttons">
                      <button
                        className='base__button-add button'
                        onClick={(e) => this.handleMetaDataDelete(metaData, i, e)}
                        disabled={(i === 0 && metaData.length === 1) || isLoading}
                      ><span>-</span></button>
                    </div>
                  }

                </div>
              ))}
              <div className="fullcell">
              {type === TYPE.JASPER &&
                <button className="button add-row-btn" type="button" disabled={isLoading} onClick={this.handleMetaDataAdd}>
                  Add Variable
                </button>
              }
              </div>
              
            </section>
          }

          {type === TYPE.CKE && !fileUploaded &&
            <div className="document-editor">
              <div className="document-editor__toolbar"><div></div></div>
              <div className="document-editor__editable-container">
              <CKEditor
                  editor={DecoupledEditor}
                  config={{
                    fontFamily: { options: [...this.customFonts] },
                    fontSize: { options: [9, 11, 13, 'default', 17, 19, 21] },
                    plugins: [Heading, Font, Essentials, Bold, Italic, Alignment, Paragraph, List, Table, insertFont64, insertVariable, insertCustomTable, insertImage64],
                    toolbar: ['heading', '|', 'fontFamily', 'fontSize', '|', 'bold', 'italic', '|', 'alignment', '|', 'bulletedList', 'numberedList', '|', 'insertFont64', 'insertCustomTable', 'insertImage64', 'insertVariable', '|', 'undo', 'redo']
                  }}
                  data={htmlTemplate}
                  onInit={editor => {
                    editor.ui.view.editable.element.parentElement
                      .insertBefore(editor.ui.view.toolbar.element, editor.ui.view.editable.element);
                    const toolbar = document.querySelector('.document-editor__toolbar div');
                    toolbar.parentElement
                      .insertBefore(editor.ui.view.toolbar.element, toolbar);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    console.log(data);
                    this.onHtmlChange(data);
                  }}
                />
              </div>      
            </div>
          }

          <br /><br />

          {(type === TYPE.HTML || type === TYPE.CKE) && fileUploaded &&
            <div className="newtemplate__metaTable">
              {(tables && tables.length > 0) && (<p><label>Tables</label></p>)}
              {tables.map((table, i) => (
                <div key={`mataData-${i}`} className="newtemplate__tables">
                  <div className="newtemplate__metaData ">
                    <div className="newtemplate__metaData-inputs newtemplate__tables-inputs">
                      <CustomInput placeholder="Table Name" 
                        isError={table.tableName === '' && tablesError}
                        value={table.tableName}
                        onChange={(e) => this.onTableNameChange(i, e)}
                        disabled={isLoading} />
                        
                      <CustomInput placeholder="System Name"
                        isError={table.systemTableName === '' && tablesError}
                        value={table.systemTableName}
                        onChange={(e) => this.onTablesSystemNameChange(i, e)}
                        disabled={true} />
                    </div>
                    <div className="newtemplate__metaData-buttons"></div>
                  </div>
                  {table.columns.map((column, j) => (
                    <div className="newtemplate__metaData-inputs" key={`mataData-${j}`}>
                      <CustomInput placeholder="Column Name"
                        isError={column.columnName === '' && tablesError}
                        value={column.columnName}
                        onChange={(e) => this.onColumnNameChange(i, j, e)}
                        disabled={true} />
                      <input className="hideMe" />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          }

          <p className="text-center">
            <button className="button" type="submit" disabled={isLoading}>
              {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
              {!isLoading && 'Submit'}
            </button>
          </p>
          
        </form>
      </div>
    );
  }

  onHtmlChange = (htmlTemplate) => {
    this.setState({
      htmlTemplate
    });

  }

  onTemplateNameChange = (e) => {
    this.setState({
      templateName: e.target.value,
      templateNameError: false,
    })
  }

  onBlur = (fieldName, e) => {
    this.setState({
      [fieldName]: e.target.value.trim(),
    });
  }

  onFileChange = (file) => {
    this.setState({ file });
  }

  onChangeType = (type) => () => {
    const tables = [{
      tableName: '',
      systemTableName: '',
      columns: [{
        columnName: ''
      }]
    }];

    this.setState({ type, tables });
  }

  handleMetaDataDelete = (metaData, index, e) => {
    e.preventDefault();
    if (metaData.length === 1) {
      return null;
    }

    this.setState({
      metaData: metaData.filter((item, i) => i !== index),
    });
  }

  handleTableColumnsDelete = (tables, index, columnIndex, e) => {
    e.preventDefault();
    if (tables[index].columns.length === 1)
      return null;
    tables[index].columns = tables[index].columns.filter((item, i) => i !== columnIndex)
    this.setState({
      tables
    });
  }

  onMetadataNameChange = (index, e) => {
    const metaData = this.state.metaData.map((data, i) => {
      if (i === index) {
        return {
          ...data,
          name: e.target.value,
        }
      }

      return data;
    })
    this.setState({
      metaData,
    });
  }

  onColumnNameChange = (index, columnIndex, e) => {
    const column = this.state.tables[index].columns[columnIndex];
    column.columnName = e.target.value;
    this.setState({
      tables: this.state.tables
    });
  }

  onTableNameChange = (index, e) => {
    const tables = this.state.tables;

    tables[index].tableName = e.target.value;

    this.setState({  tables });
  }

  onMetadataSystemNameChange = (index, e) => {
    const metaData = this.state.metaData.map((data, i) => {
      if (i === index) {
        return {
          ...data,
          systemName: e.target.value,
        }
      }

      return data;
    })
    this.setState({
      metaData,
    });
  }

  onTablesSystemNameChange = (index, e) => {
    const tables = this.state.tables.map((data, i) => {
      if (i === index) {
        return {
          ...data,
          systemTableName: e.target.value,
        }
      }

      return data;
    })
    this.setState({
      tables,
    });
  }

  handleMetaDataAdd = (e) => {
    e.preventDefault();
    const { metaData } = this.state;
    this.setState({
      metaData: [
        ...metaData,
        {
          name: '',
          systemName: '',
        }
      ]
    })
  }

  handleTableAdd = (e) => {
    e.preventDefault();
    const { tables } = this.state;
    this.setState({
      tables: [
        ...tables,
        {
          tableName: '',
          systemTableName: '',
          columns: [{
            columnName: ''
          }]
        }
      ]
    })
  }

  handleTableColumnAdd = (tables, index, e) => {
    e.preventDefault();
    tables[index].columns.push({
      columnName: ''
    });
    this.setState({
      tables
    })
  }

  formValidate = () => {
    const { templateName, metaData, tables, file, type } = this.state;

    let isValid = true;

    const filledMetaData = metaData.filter((data) => data !== '')
    if (filledMetaData.length === 0) {
      console.log('filledMetaData');
      // this.setState({
      //   errorMessage: "Form has invalid data",
      //   metaDataError: true,
      // });
    } else {
      filledMetaData.forEach((data) => {
        if (!data) {
          this.setState({
            errorMessage: "Form has invalid data",
            metaDataError: true,
          });

          isValid = false;
        }
      })
    }

    if (type === TYPE.HTML) {
      const test = JSON.stringify(tables).search('""');
      if (test > -1) {
        console.log(JSON.stringify(tables));
        this.setState({
          errorMessage: "Form has invalid data",
          tablesError: true,
        });

        // isValid = false;      
      }
    }

    if (!file && type === TYPE.JASPER) {
      this.setState({
        errorMessage: "Form has invalid data",
        fileError: true,
      });

      isValid = false;
    }

    if (!templateName) {
      this.setState({
        errorMessage: "Form has invalid data",
        templateNameError: true,
      });

      isValid = false;
    }

    return isValid;
  }

  checkHTML = () => {

  }

  putHTML = () => {

  }

  onSubmit = (e) => {
    e.preventDefault();
    const authToken = getAuthToken();
    const {
      templateName, metaData, tables, type, fileUploaded, id, htmlTemplate
    } = this.state;

    // console.log(tables);

    if (this.formValidate()) {
      this.setState({
        isLoading: true,
      })

      let data = null;
      const formData = new FormData();

      formData.append('name', templateName);

      if (type === TYPE.JASPER || !fileUploaded)
        formData.append('file', this.state.file);

      if (type === TYPE.JASPER || fileUploaded)
        formData.append('items', JSON.stringify({ fields: metaData, tables }));

      let url = (type === TYPE.HTML || type === TYPE.CKE) ? 'v1/template/html' : 'v1/template/jaspersoft';
      let method = (fileUploaded) ? 'put' : 'postMultipartFormData';
      const content = htmlData(stylesHTML, htmlTemplate.replaceAll('<widget>', '@@@').replaceAll('</widget>', '@@@'));

      if (type === TYPE.CKE && !fileUploaded) {
        url = 'v1/constructor/template';
        method = 'post'
      }
      if (type === TYPE.JASPER) {
        method = 'postMultipartFormData';
      }
      if (method === 'post') {
        data = { templateName, content }
      } else if (method === 'put') {
        data = {
          id,
          name: templateName,
          items: {
            fields: metaData,
            tables
          }
        };
      } else
        data = formData;

      Api[method](url, data, authToken).then((response) => {
        if (type === TYPE.JASPER || fileUploaded)
          this.props.history.push('/explorer/new-document');
        else
          setTimeout(() => {
            console.log(response.data.items.tables);
            response.data.items.fields.forEach(v => {
              if (!v.name) v.name = '';
            })
            response.data.items.tables.forEach(v => {
              if (!v.tableName) v.tableName = '';
            })
            this.setState({
              submited: true,
              isLoading: false,
              id: response.data.id,
              templateName: templateName,
              file: this.state.file,
              metaData: response.data.items.fields,
              tables: response.data.items.tables,
              successMessage: '',
              htmlTemplate: '',
              fileUploaded: true
            });
            console.log(this.state);
          }, 2000);
      }).catch((error) => {
        checkAuth(error.response, this.props.history);
        this.setState({
          errorMessage: error || 'Server Erorr',
          isLoading: false,
        })
      })
    }
  }
}

export default withRouter(NewTemplate);

// eslint-disable-next-line
String.prototype.replaceAll = function (search, replacement) {
  var target = this;
  return target.replace(new RegExp(search, 'g'), replacement);
};