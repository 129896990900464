import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

import { tableIcon } from './imageIcon';


function insertCustomTable(editor) {
  console.log('insertTable was initialized', editor);

  function openModal() {
    const event = new CustomEvent('openModal', { detail: 'table' });
    window.dispatchEvent(event);
    return new Promise((resolve, reject) => {
      window.addEventListener('closeModal', evt => {
        resolve(evt.detail);
      }, false);
    })
  }

  // ------------------

  editor.ui.componentFactory.add('insertCustomTable', locale => {
    const view = new ButtonView(locale);
    const model = editor.model;

    view.set({
      label: 'Insert table',
      icon: tableIcon,
      tooltip: true
    });

    model.schema.extend('table', { allowAttributes: 'tableName' });
    editor.conversion.attributeToAttribute({
      model: {
        name: 'table',
        key: 'tableName'
      },
      view: {
        key: 'data-tableName'
      }
    });

    view.on('execute', () => {
      openModal().then(modalResult => {
        const tableUtils = editor.plugins.get('TableUtils');
        model.change(writer => {
          const table = tableUtils.createTable(writer, 1, modalResult.tableColumns || 2);
          writer.setAttribute('tableName', modalResult.tableName || '', table);
          model.insertContent(table, model.document.selection);
          writer.setSelection(table.getChild(0).getChild(0), 'end');
        });
      });
    });

    return view;
  });
}

export default insertCustomTable;