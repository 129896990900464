import React, { Component } from 'react';
import { format } from 'date-fns';
import { withRouter } from 'react-router';

import { Api } from '../../services/api';
import { checkAuth } from '../../services/utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';


import QRCode from 'qrcode-react';
import './ResultForm.scss';

class ResultForm extends Component {
  state = {
    QRLink: '',
    json: {},
  }

  componentDidMount() {
    const { document } = this.props;
    const { documentId } = document;

    // console.log(this.props);

    if (documentId) {
      Api.post(`v1/pdf/verifier`, { documentID: documentId }).then((response) => {
        this.setState({
          QRLink: response.data.documentLink || response.data.link,
        });
      }).catch((error) => {
        checkAuth(error.response, this.props.history);
      });
    }
  }

  render() {
    const { QRLink, json } = this.state;
    const { document } = this.props;
    const { documentId, transactionId, documentName, recipientName, issueTimestamp, expireTimestamp } = document;
    const data = "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(json));
    
    return (
      <div className="base">
        <div className="base__header">
          <div className="base__header-wrapper">
            <FontAwesomeIcon icon={faArrowLeft} onClick={this.onBackClick} />
                &nbsp;&nbsp;&nbsp;
            <h1 className="base__title">Document Submitted</h1>
          </div>
        </div>
        <section className="resultPage">
          <div className="text-center">
            {QRLink &&
              <div className="result-form__qr-code text-center">
                <QRCode size={150} value={QRLink} />
              </div>
            }
          </div>
          <div className="result-form__params params">

            {documentName && <div className="params__field">
              <div className="params__key">Document Name:</div>
              <div className="params__value">{documentName}</div>
            </div>}

            {recipientName && <div className="params__field">
              <div className="params__key">Document Holder:</div>
              <div className="params__value">{recipientName}</div>
            </div>}

            {issueTimestamp && <div className="params__field">
              <div className="params__key">Issue Date:</div>
              <div className="params__value">{format(new Date(issueTimestamp), 'dd/MM/yyyy')}</div>
            </div>}

            {!!expireTimestamp && <div className="params__field">
              <div className="params__key">Expiration Date:</div>
              <div className="params__value">{expireTimestamp ? format(new Date(document.expireTimestamp), 'dd/MM/yyyy') : 'N/A'}</div>
            </div>}

            {transactionId && <div className="params__field">
              <div className="params__key">Tx ID:</div>
              <div className="params__value">{transactionId}</div>
            </div>}

            {documentId && <div className="params__field">
              <div className="params__key">Document ID:</div>
              <div className="params__value ellipsis">{documentId}</div>
            </div>}

            {Object.keys(json).length !== 0 && (
              <div className="result-form__download">
                <label className="result-form__label label">Document Certificate JSON</label>
                <a className="button button_alt" href={`data:${data}`} download="data.json">↓ Download</a>
              </div>
            )}
          </div>
        </section>
        
      </div>
    );
  }

  onBackClick = () => {
    this.props.history.push('/explorer/new-document');
  }
}

export default withRouter(ResultForm);