import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

import { vaiableIcon } from './imageIcon';

function insertFont64(editor) {
  console.log('InsertFont64 was initialized', editor);

  function openModal() {
    const event = new CustomEvent('openModal', { detail: 'font' });
    window.dispatchEvent(event);
    return new Promise((resolve, reject) => {
      window.addEventListener('closeModal', evt => {
        resolve(evt.detail);
      }, false);
    })
  }

  function refresh(detail) {
    const event = new CustomEvent('updateEditor', { detail });
    window.dispatchEvent(event);
  }

  editor.ui.componentFactory.add('insertFont64', locale => {
    const view = new ButtonView(locale);
    const fontFamily = editor.config.get('fontFamily');

    view.set({
      label: 'Insert font',
      icon: vaiableIcon,
      tooltip: true
    });

    // Callback executed once the image is clicked.
    view.on('execute', () => {
      openModal().then(modalResult => {
        const fileName = modalResult.fileName.split('.')[0];
        const options = [...fontFamily.options, fileName];

        const newFont = new FontFace(fileName, `url(${modalResult.file64})`);
        newFont.load().then(loaded_face => {
          document.fonts.add(loaded_face);
        });

        refresh({ options, modalResult });
      });
    });

    return view;
  });
}



export default insertFont64;