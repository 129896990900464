import React, { createContext, Component } from 'react';

import { getProfile, refreshProfile, logout } from './utils';

const ROLES = {
  USER: 'USER',
  EDITOR: 'EDITOR',
  ADMIN: 'ADMIN',
  NEW: 'NEW',
};

const GlobalContext = createContext();

const GlobalProvider = GlobalContext.Provider;
const GlobalConsumer = GlobalContext.Consumer;

const user = getProfile() || ({ role: ROLES.USER });

class GlobalStore extends Component {

  state = { user };

  setUser = (user) => {
    this.setState({ user });
  };

  clearUser = () => {
    const user = { role: ROLES.USER };
    this.setState({ user });

    logout();
  }

  refreshUser = () => {
    refreshProfile();
  }

  render() {
    // Pass down the state and the onToggleLight action
    const value = {
      user: this.state.user,
      setUser: this.setUser,
      clearUser: this.clearUser,
      refreshUser: this.refreshUser
    };

    return (
      <GlobalContext.Provider value={value}>
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}

export { GlobalStore, GlobalContext, GlobalProvider, GlobalConsumer, ROLES };