import React, { Component } from 'react';
import DatePicker from 'react-datepicker';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';

import 'react-datepicker/dist/react-datepicker.css';

class CustomInput extends Component {
  render () {
    const { value, isError, readOnly, disabled, placeholder, onClick, onChange, className, type } = this.props;
    // console.log(2, this.props);
    // console.log(placeholder)
    const name = '_' + btoa((placeholder || '').replace(/[^\w\s!?]/g, '') + Date.now())

    return (
      <div className="form-control">
        <input
          placeholder=" "
          type={type || 'text'}
          className={'input ' + (isError ? 'input_error ' : ' ') + (className || '')}
          onClick={onClick}
          onChange={onChange}
          value={value}
          disabled={!disabled ? undefined : 'disabled'}         
          readOnly={readOnly}
          autoComplete="new-password"
          name={name}
        />
        <label>{placeholder}</label>
      </div>
    )
  }
}

class CustomInputFile extends Component {
  state = { file: null };

  onInputAttachClick = (e) => {
    e && e.preventDefault();
    document.getElementById('generic-input-file').click();
  }

  onFileChanged = (e) => {
    this.setState({ file: e.target.files[0] });
    this.props.onChange && this.props.onChange(e.target.files[0]);
  }

  render() {
    const { disabled, placeholder, accept, className } = this.props;

    return (
      <div className="inputFile">
        <input
          className={'input input_file ' + (className || '')}
          type="text" readOnly
          onClick={this.onInputAttachClick}
          value={this.state.file ? this.state.file.name : ''}
          placeholder={placeholder}
          disabled={disabled}
        />
        <input 
          className="input_hidden" id="generic-input-file" type="file" 
          accept={accept} 
          onChange={this.onFileChanged} 
          disabled={disabled}
        />
        <span>
          <FontAwesomeIcon icon={faPaperclip} />
        </span>
      </div>
    )
  }
}

class CustomDateInput extends Component {
  render() {
    return <CustomInput {...this.props} readOnly={true} placeholder={this.props.children} />
  }
}

class DateInput extends Component {
  render() {
    const customInput = <CustomDateInput>{this.props.placeholder}</CustomDateInput>;
    return <DatePicker dateFormat="dd/MM/yyyy" {...this.props} isClearable={true} customInput={customInput} />
  }
}

export { CustomInput, CustomInputFile, DateInput };